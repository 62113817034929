<template>
    <io-autocomplete
        :model-value="itemSelected"
        @update:model-value="onUpdate"
        v-bind="VAutocompleteProps"
        :query="companies"
        :fetch-items="fetchItems"></io-autocomplete>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { ref } from 'vue';
    import { getSupplier, getSuppliers } from '@/views/AssetsManagement/composables/suppliers';
    import { computed } from 'vue';
    import { omit } from 'lodash';
import { watch } from 'vue';

    const props = defineProps<{
        modelValue: number | null | any;
        companies: number[];
    }>();

    const itemSelected = ref<number | null>();

    const companies = computed(() => props.companies ?? []);

    const VAutocompleteProps = computed(() => omit(props, ['modelValue']));

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | null): void;
    }>();

    async function fetchItems({ page, search, id }: { page?: number; search?: string; id?: number } = {}) {
        if (id) {
            return getSupplier(id);
        }
        return getSuppliers({
            pagination: {
                page,
            },
            sort: ['name:asc'],
            filters: {
                name: {
                    $containsi: search,
                },
                companies: {
                    id: {
                        $in: companies.value,
                    },
                },
            },
        });
    }

    function onUpdate(value: number | null) {
        itemSelected.value = value;
        emit('update:modelValue', value);
    }

    watch(
        () => props.modelValue,
        (value) => {
            itemSelected.value = value;
        },
        {
            immediate: true,
        }
    );
</script>

<style scoped></style>

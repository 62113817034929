<template>
    <v-chart
        ref="chart"
        :autoresize="true"
        class="chart"
        :option="options" />
</template>

<script setup lang="ts">
    import { ref, computed, onMounted, watch } from 'vue';
    import useLineChart from '@/components/charts/line';
    import useBarChart from '@/components/charts/bar';
    import usePieChart from '@/components/charts/pie';
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import { PieChart, LineChart, BarChart } from 'echarts/charts';
    import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, DataZoomComponent, ToolboxComponent } from 'echarts/components';
    import VChart, { THEME_KEY } from 'vue-echarts';

    use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent, LineChart, BarChart, GridComponent, DataZoomComponent, ToolboxComponent]);

    const props = defineProps({
        type: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        xAxisInfo: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        series_name: {
            type: String,
            required: false,
        },
        series_color: {
            type: String,
            required: false,
        },
    });

    let useChart = null;
    //const options = ref(null as any);

    const options = computed(() => {
        return useChart(props.xAxisInfo, props.data, props.title, props.series_name, props.series_color).options;
    });

    switch (props.type) {
        case 'line':
            useChart = useLineChart;
            break;
        case 'bar':
            useChart = useBarChart;
            break;
        case 'pie':
            useChart = usePieChart;
            break;
        default:
            useChart = useLineChart;
            break;
    }
</script>

<style scoped></style>

/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
// import 'vuetify/styles';
import { pt, en } from 'vuetify/locale';
// Composables
import { createVuetify } from 'vuetify';
import { vuetifyColors } from '../../theme';
import type { VAlert, VTextarea, VAutocomplete, VTextField, VSwitch, VBtn, VFileInput, VSelect } from 'vuetify/lib/components/index.mjs';

import 'vuetify/styles';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    defaults: {
        VTextField: {
            // density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
            bgColor: 'gray-100',
            hideSpinButtons: true,
        } as VTextField['$props'],
        VBtn: {
            elevation: 1,
        } as VBtn['$props'],
        VAutocomplete: {
            // density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
        } as VAutocomplete['$props'],
        VTextarea: {
            // density: 'compact',
            bgColor: 'gray-100',
            hideDetails: 'auto',
            autoGrow: true,
            variant: 'outlined',
        } as VTextarea['$props'],
        VFileInput: {
            variant: 'outlined',
            hideDetails: 'auto',
            bgColor: 'gray-100',
        } as VFileInput['$props'],
        VSwitch: {
            hideDetails: 'auto',
        } as VSwitch['$props'],
        VSelect: {
            variant: 'outlined',
            hideDetails: 'auto',
        } as VSelect['$props'],
    },
    locale: {
        locale: 'pt',
        fallback: 'en',
        messages: { pt, en },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1536,
        },
    },
    theme: {
        themes: {
            light: {
                colors: vuetifyColors(),
            },
        },
    },
});

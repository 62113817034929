import { ResultSet, SeriesNamesColumn } from '@cubejs-client/core';
import { BarSeriesOption, EChartsOption } from 'echarts';
import _ from 'lodash';
import { dateToGranularity } from '../useChart';
import { TimeDimensionGranularity } from '../..';

export function bar(resultSet: ResultSet, defaultOptions: EChartsOption = {}) {
    const granularity = _.get(resultSet.query(), 'timeDimensions[0].granularity') as TimeDimensionGranularity;
    const measures = _.get(resultSet.query(), 'measures', []).join(' / ');
    const dimensions = _.get(resultSet.query(), 'dimensions', []).join(' / ');
    const xAxis = resultSet.chartPivot().map((p) => dateToGranularity(p.x, granularity) as string);

    const series = resultSet.series().map((s) => {
        const serieName = resultSet.seriesNames().find((e) => e.key === s.key) as SeriesNamesColumn;

        return {
            name: serieName.yValues[0],
            type: 'bar',
            stack: serieName.yValues[0],
            data: s.series.map((e) => ({
                value: e.value,
                // itemStyle: {
                //     color: customColors.getColorByKey(s.key),
                // },
            })),
            emphasis: {
                focus: 'series',
            },
            // hide numbers
            // label: {
            //     show: true,
            //     position: 'inside',
            // },
        } as BarSeriesOption;
    });

    let options: EChartsOption = {
        legend: {
            data: resultSet.seriesNames().map((e) => ({
                name: e.yValues[0],
                // itemStyle: {
                //     color: customColors.getColorByKey(e.key),
                // },
            })),
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}',
        },
        xAxis: {
            type: 'category',
            data: xAxis,
            name: granularity || dimensions,
        },
        yAxis: {
            type: 'value',
            name: measures,
            nameLocation: 'middle',
            nameGap: 60,
        },
        series,
        dataZoom: [
            {
                type: 'slider',
            },
            {
                type: 'inside',
            },
        ],
        toolbox: {
            feature: {
                magicType: { show: true, type: ['line'] },
            },
        },
    };

    return _.merge(options, defaultOptions);
}

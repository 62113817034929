import { defineStore } from 'pinia';
import _ from 'lodash';
import { VAlert } from 'vuetify/lib/components/index.mjs';

type GetValue<T, K extends keyof T> = T[K];

export interface Alert extends GetValue<VAlert, '$props'> {
    show?: boolean;
    timeout?: number;
}

const defaultAlert: Alert = {
    show: false,
    timeout: 3000,
    closable: true,
};

export const useAlert = defineStore({
    id: 'alertStore',
    state: () => ({
        alert: {
            show: false,
            text: '',
            variant: 'outlined',
            color: 'success',
            timeout: 3000,
        } as Alert,
    }),
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    actions: {
        setAlert(data: Alert) {
            // @ts-ignore
            this.alert = data;
        },
        showAlert(data: Alert) {
            data.show = true;
            this.alert = _.assign(_.clone(defaultAlert), data);
        },
        hideAlert() {
            this.alert = defaultAlert;
        },
    },
});

import { APIResponseMany, APIResponseSingle } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/companies';

export async function getCompanies(query = {}): AxiosPromise<APIResponseMany<'Company'>> {
    _.set(query, 'filters.users.id.$in', [useAuthStore().getUser?.id]);
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getCompany(id: number, query = {}): AxiosPromise<APIResponseSingle<'Company'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export default {
    getCompanies,
};

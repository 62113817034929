import slugify from 'slugify';
import _ from 'lodash';

export function getErrorKey(error: unknown, localKey: string = ''): string {
    if (!localKey.endsWith('.')) localKey += '.';
    const slug = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
        replacement: '_',
        trim: true,
        lower: true,
    });

    return slug == 'unknown_error' ? slug : `${localKey}${slug}`;
}

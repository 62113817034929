import _ from 'lodash';
import { ResultSet, TableColumn } from '@cubejs-client/core';
// @ts-ignore
import { TimeDimensionGranularity } from '../../index.d.ts';
import type { VDataTableVirtual } from 'vuetify/lib/components/index.mjs';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { dateToGranularity } from '../useChart';
import { computed } from 'vue';
import { unflatten } from 'flat';
import { ComposerTranslation } from 'vue-i18n';

export function table(
    resultSet: ResultSet,
    {
        defaultOptions,
        t,
        $route,
    }: {
        defaultOptions?: any;
        t: ComposerTranslation;
        $route: RouteLocationNormalizedLoaded;
    },
) {
    const granularity = computed(() => {
        const query = unflatten($route.query);
        return _.get(query, 'timeDimensions[0].granularity', 'day') as TimeDimensionGranularity;
    });

    function parseHeaderTitle(header: TableColumn): string {
        const headerSplit = header.key.split('.');
        const lastKey = headerSplit[headerSplit.length - 1];
        if (Object.values(TimeDimensionGranularity).includes(lastKey as TimeDimensionGranularity)) {
            return t ? t(`cube.timeGranularity.${lastKey}`) : lastKey;
        }

        return t ? t(`intelligence.cube.cubes.${header.key}`) : header.key;
    }

    const headers: VDataTableVirtual['$props']['headers'] = resultSet.tableColumns().map((c) => ({
        key: c.key,
        title: parseHeaderTitle(c),
        align: c.type == 'number' ? 'end' : 'start',
    }));

    function parseValue(row: any, header: TableColumn) {
        if (header.type == 'time') {
            return dateToGranularity(row[header.key], granularity.value);
        }

        if (header.type == 'number') {
            // round to 2 decimal places
            return (Math.ceil(row[header.key] * 100) / 100).toFixed(2);
        }

        return row[header.key];
    }

    const items = resultSet.tablePivot().map((row) => {
        const item: Record<string, any> = {};

        resultSet.tableColumns().forEach((header) => {
            // @ts-ignore
            item[header.key] = parseValue(row, header);
        });

        return item;
    });

    return {
        headers,
        items,
    } as VDataTableVirtual['$props'];
}

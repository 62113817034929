<template>
    <div class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-y-4">
        <p class="tw-text-9xl">404</p>
        <p class="tw-text-2xl">{{ $t('shared.notFound') }}</p>
        <v-btn
            elevation="0"
            density="compact"
            class="tw-mr-3 tw-h-10 tw-bg-primary tw-text-white"
            rounded="lg"
            @click="() => $router.push('/')"
            >
            {{ $t('shared.goToHome') }}
        </v-btn>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>

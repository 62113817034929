<template>
   
        
        <!-- 
            comentario:
            <template v-slot:prepend>
            <v-app-bar-nav-icon @click="openSidebar"></v-app-bar-nav-icon>
        </template> 
    -->

      <!--   
        <v-app-bar color="primary">
        <v-app-bar-title>ioEnergy</v-app-bar-title>
    </v-app-bar> 
-->
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useAppStore } from '../store/app';

    const appStore = useAppStore();

    function openSidebar() {
        appStore.toggleSidebar();
    }
</script>

<style scoped></style>

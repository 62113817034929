import { ContentType } from '@/contentTypes';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: '' as string,
        user: null as ContentType<'User'> | null,
        primaryColor: '' as string,
        activeCompany: null as ContentType<'Company'> | null,
    }),
    getters: {
        isAuthenticated(): boolean {
            return !!this.token;
        },
        getToken(): string {
            return this.token;
        },
        getUser(): ContentType<'User'> | null {
            return this.user;
        },
        getPrimaryColor(): string {
            return this.primaryColor;
        },
        getActiveCompany(): ContentType<'Company'> | null {
            return this.activeCompany;
        },
        getUserCompaniesIds(): number[] {
            if (!this.user?.companies) return [];
            return this.user.companies.map((c) => (typeof c == 'object' ? (c.id as number) : c));
        },
        hasPermission(): (permission: string) => boolean {
            return (permission: string) => {
                if (!this.activeCompany) return false;

                let licence = this.activeCompany?.io_licenses?.find((l) => l.isActive);
                return licence?.io_modules?.some((m) => m.permission === permission);
            };
        },
    },
    actions: {
        setToken(token: string) {
            this.token = token;
        },
        setUser(user: ContentType<'User'>) {
            this.user = user;
        },
        setPrimaryColor(color: string) {
            this.primaryColor = color;
        },
        setActiveCompany(company: ContentType<'Company'>) {
            this.activeCompany = company;
        },
    },
    persist: true,
});

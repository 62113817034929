import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/assets';
export const API_REF = 'api::asset.asset';

export async function getAssets(query = {}): AxiosPromise<APIResponseMany<'Asset'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function createAsset(data: ContentType<'Asset'>): AxiosPromise<APIResponseSingle<'Asset'>> {
    return axios.post(API_URL, { data });
}

export async function getAsset(id: number, query = {}): AxiosPromise<APIResponseSingle<'Asset'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export async function getAssetTotal(): AxiosPromise<APIResponseSingle<'Asset'>> {

    return axios.get(`${API_URL}`);
}


export async function updateAsset(id: number, data: ContentType<'Asset'>, query = {}): AxiosPromise<APIResponseSingle<'Asset'>> {
    const searchParams = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${searchParams}`, { data });
}

export async function deleteAssets(ids: number[]): AxiosPromise<APIResponseMany<'Asset'>> {
    const query = qs.stringify({
        filters: {
            id: {
                $in: ids,
            }
        }
    })
    return axios.delete(`${API_URL}/many?${query}`);
}

export async function deleteAsset(id: number): AxiosPromise<APIResponseSingle<'Asset'>> {
    return axios.delete(`${API_URL}/${id}`);
}

export default {
    getAssets,
    createAsset,
    getAsset,
    getAssetTotal,
    updateAsset,
    deleteAssets,
    deleteAsset,
};

import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/locations';
export const API_REF = 'api::location.location';

export async function getLocations(query = {}): AxiosPromise<APIResponseMany<'Location'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export default {
    getLocations,
};

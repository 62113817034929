<template>
    <io-autocomplete
        :model-value="itemSelected"
        @update:model-value="onUpdate"
        v-bind="VAutocompleteProps"
        :query="companies"
        :fetch-items="fetchModels"></io-autocomplete>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { computed, ref, watch } from 'vue';
    import { getModels, getModel } from '@/views/AssetsManagement/composables/models';
    import { omit } from 'lodash';

    const props = defineProps<{
        modelValue: number | null | any;
        companies: number[];
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | null): void;
    }>();

    const VAutocompleteProps = computed(() => omit(props, ['modelValue']));

    const itemSelected = ref<number | null>();

    const companies = computed(() => (props.companies ?? []));

    async function fetchModels({ page, search, id }: { page?: number; search?: string; id?: number } = {}) {
        if (id) {
            return getModel(id);
        }

        return getModels({
            pagination: {
                page,
            },
            sort: ['name:asc'],
            filters: {
                name: {
                    $containsi: search,
                },
                companies: {
                    id: {
                        $in: companies.value,
                    },
                },
            },
        });
    }

    function onUpdate(value: number | null) {
        itemSelected.value = value;
        emit('update:modelValue', value);
    }

    watch(
        () => props.modelValue,
        (value) => {
            itemSelected.value = value;
        },
        {
            immediate: true,
        }
    );
</script>

<style scoped></style>

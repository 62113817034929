import _ from 'lodash';

const useLineChart = (xAxis: any[], series_date: any[], title: string, series_name: string,  _options = {}) => {
    const options = {
        tooltip: {
            trigger: 'axis',
            position: function (pt: any) {
                return [pt[0], '10%'];
            },
        },
        title: {
            left: 'center',
            text: title,
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none',
                },
                restore: {},
                saveAsImage: {},
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
            },
            {
                start: 0,
                end: 100,
            },
        ],
        series: [
            {
                name: series_name,
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {
                    color: '#d1284c',
                },
                areaStyle: {
                    // simple color
                    color: '#d1284c',
                },
                data: series_date,
            },
        ],
    };

    return {
        options,
    };
};

export default useLineChart;

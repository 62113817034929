import { APIResponseMany, APIResponseSingle } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/locations';

export async function getLocations(query = {}): AxiosPromise<APIResponseMany<'Location'>> {
    if (!_.has(query, 'filters.departments.company.id.$in')) _.set(query, 'filters.departments.company.id.$in', useAuthStore().getUserCompaniesIds);
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getLocation(id: number, query = {}): AxiosPromise<APIResponseSingle<'Location'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export default {
    getLocations,
    getLocation,
};

import axios from '@axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/home-assistant/alarmist';

export async function getAlarmist(query = {}) {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function createAlarmist(data: any) {
    return axios.post(`${API_URL}`, { data });
}

export async function updateAlarmist(id: string, data: any) {
    return axios.put(`${API_URL}/${id}`, { data });
}

export async function deleteAlarmist(id: string) {
    return axios.delete(`${API_URL}/${id}`);
}

export default {
    getAlarmist,
    createAlarmist,
    updateAlarmist,
    deleteAlarmist
};

<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('dashboards.dashboard') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <div
            v-if="errorMessage"
            class="tw-mb-4">
            <v-alert
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row class="tw-py-4">
            <v-col
                class="mx-auto"
                cols="12"
                lg="3"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-pr-2 tw-pt-2">
                    <v-row>
                        <v-col cols="9">
                            <v-card-subtitle> {{ $t('dashboards.assets.labels.assets') }} </v-card-subtitle>
                            <v-card-text>
                                <div>
                                    <p>{{ $t('dashboards.assets.labels.installed') }}: 6</p>
                                    <p>{{ $t('dashboards.assets.labels.pending') }}: 12</p>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col
                            class="tw-flex tw-justify-end"
                            cols="3">
                            <v-icon class="tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-5 tw-text-red-600"> mdi-barcode </v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-pr-2 tw-pt-2">
                    <v-row>
                        <v-col cols="9">
                            <v-card-subtitle> {{ $t('dashboards.assets.labels.sensors') }} </v-card-subtitle>
                            <v-card-text>
                                <div>
                                    <p class="tw-mt-4">{{ $t('dashboards.assets.labels.total') }}: 6</p>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col
                            class="tw-flex tw-justify-end"
                            cols="3">
                            <v-icon class="tw-rounded-md tw-bg-yellow-100 tw-bg-opacity-100 tw-p-5 tw-text-yellow-200"> mdi-leak </v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-pr-2 tw-pt-2">
                    <v-row>
                        <v-col cols="9">
                            <v-card-subtitle> {{ $t('dashboards.assets.labels.batteries') }} </v-card-subtitle>

                            <v-card-text>
                                <div>
                                    <p class="tw-mt-4">{{ $t('dashboards.assets.labels.total') }}: 6</p>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col
                            class="tw-flex tw-justify-end"
                            cols="3">
                            <v-icon class="tw-rounded-md tw-bg-blue-100 tw-bg-opacity-100 tw-p-5 tw-text-blue-500"> mdi-battery </v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="3"
                md="4"
                sm="6">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-pr-2 tw-pt-2">
                    <v-row>
                        <v-col cols="9">
                            <v-card-subtitle> {{ $t('dashboards.assets.labels.next_battery_change') }} </v-card-subtitle>

                            <v-card-text>
                                <div>
                                    <p class="tw-mt-4">20-03-2024</p>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col
                            class="tw-flex tw-justify-end"
                            cols="3">
                            <v-icon class="tw-rounded-md tw-bg-gray-400 tw-bg-opacity-100 tw-p-5 tw-text-gray-500"> mdi-battery-clock </v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <p class="tw-text-xl tw-font-bold">Título</p>
        <div class="tw-flex tw-flex-col tw-gap-4">
            <v-row class="tw-pb-5">
                <v-col
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6">
                    <v-card
                        variant="flat"
                        class="mx-auto tw-bg-white tw-pr-2 tw-pt-2">
                        <v-row>
                            <v-col class="tw-mb-0 tw-pb-0">
                                <v-card-subtitle> Número de Ativos </v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="9"
                                class="tw-mt-0 tw-pt-0">
                                <v-card-text>
                                    <p>6</p>
                                </v-card-text>
                            </v-col>

                            <v-col
                                class="tw-mt-0 tw-flex tw-justify-end tw-self-start tw-pt-0"
                                cols="3">
                                <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-5 tw-text-green-500"> mdi-barcode </v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6">
                    <v-card
                        variant="flat"
                        class="mx-auto tw-bg-white tw-pr-2 tw-pt-2">
                        <v-row>
                            <v-col class="tw-mb-0 tw-pb-0">
                                <v-card-subtitle> Número de Componentes </v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="9"
                                class="tw-mt-0 tw-pt-0">
                                <v-card-text>
                                    <p>6</p>
                                </v-card-text>
                            </v-col>

                            <v-col
                                class="tw-mt-0 tw-flex tw-justify-end tw-self-start tw-pt-0"
                                cols="3">
                                <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-5 tw-text-green-500">mdi-account-group</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6">
                    <v-card
                        variant="flat"
                        class="mx-auto tw-bg-white tw-pr-2 tw-pt-2">
                        <v-row>
                            <v-col class="tw-mb-0 tw-pb-0">
                                <v-card-subtitle> Número de Pessoas </v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="9"
                                class="tw-mt-0 tw-pt-0">
                                <v-card-text>
                                    <p>6</p>
                                </v-card-text>
                            </v-col>

                            <v-col
                                class="tw-mt-0 tw-flex tw-justify-end tw-self-start tw-pt-0"
                                cols="3">
                                <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-5 tw-text-green-500">mdi-barcode</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6">
                    <v-card
                        variant="flat"
                        class="mx-auto tw-bg-white tw-pr-2 tw-pt-2">
                        <v-row>
                            <v-col class="tw-mb-0 tw-pb-0">
                                <v-card-subtitle> Próxima Manutenção </v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="9"
                                class="tw-mt-0 tw-pt-0">
                                <v-card-text>
                                    <p>12-05-2078</p>
                                </v-card-text>
                            </v-col>

                            <v-col
                                class="tw-mt-0 tw-flex tw-justify-end tw-self-start tw-pt-0"
                                cols="3">
                                <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-5 tw-text-green-500">mdi-tools</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-data-table-server
            hover
            color="primary"
            density="comfortable"
            :loading="isLoading"
            :loading-text="$t('shared.loading')"
            :headers="headers"
            :items-per-page-options="[10, 25, 50, 100]"
            v-model:items-per-page="pageSize"
            :no-data-text="$t('dashboards.assets.batteries.errors.not_found')"
            v-model:page="page"
            :items-length="total"
            @update:options="onOptionsUpdated"
            :items="batteries"
            height="calc(100vh - 280px)"
            :fixed-header="true">
            <template #item.status="{ value }">
                <div class="tw-flex tw-w-14 tw-justify-between">
                    {{ value }}%
                    <v-icon
                        class="tw-text-red"
                        v-if="value < 25">
                        mdi-battery-low
                    </v-icon>
                    <v-icon
                        class="tw-text-yellow"
                        v-if="value >= 25 && value < 75">
                        mdi-battery-medium
                    </v-icon>
                    <v-icon
                        class="tw-text-green"
                        v-if="value >= 75">
                        mdi-battery-high
                    </v-icon>
                </div>
            </template>
        </v-data-table-server>
    </div>
</template>

<script setup lang="ts">
    import { ref, provide, computed, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import { ContentType } from '@/contentTypes';
    import { VDataTableOptions } from '@/types';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('dashboards.title'),
            },
            {
                title: $t('dashboards.assets.title'),
                to: { name: 'AssetsDashboard' },
            },
        ];
    });

    let batteries = ref<ContentType<'Battery'>[]>([]);
    const isLoading = ref(true);
    const pageCount = ref(0);
    const pageSize = ref(10);
    const errorMessage = ref('');
    const total = ref(0);
    const page = ref(1);
    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'id',
            order: 'desc',
        },
    ]);

    const firstUpdate = ref(true);

    batteries.value = [
        {
            location: 'Máquina 1',
            sensor: 'Sensor 1',
            status: 63,
            nextChange: '20-03-2024',
        },
        {
            location: 'Máquina 2',
            sensor: 'Sensor 2',
            status: 63,
            nextChange: '20-03-2024',
        },
        {
            location: 'Máquina 3',
            sensor: 'Sensor 3',
            status: 12,
            nextChange: '20-03-2024',
        },
        {
            location: 'Máquina 4',
            sensor: 'Sensor 4',
            status: 98,
            nextChange: '20-03-2024',
        },
        {
            location: 'Máquina 5',
            sensor: 'Sensor 5',
            status: 23,
            nextChange: '20-03-2024',
        },
        {
            location: 'Máquina 6',
            sensor: 'Sensor 6',
            status: 30,
            nextChange: '20-03-2024',
        },
    ];

    const headers = [
        {
            // title: $t('dashboards.assets.batteries.fields.location'),
            title: 'Máquinas',
            sortable: true,
            key: 'location',
        },
        {
            title: $t('dashboards.assets.batteries.fields.sensor'),
            sortable: true,
            key: 'sensor',
        },
        {
            title: $t('dashboards.assets.batteries.fields.status'),
            sortable: true,
            key: 'status',
        },
        {
            title: $t('dashboards.assets.batteries.fields.next_battery_change'),
            sortable: true,
            key: 'nextChange',
        },
    ] as VDataTableServer['headers'];

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        // page.value = options.page;
        // pageSize.value = options.itemsPerPage;
        // sort.value = options.sortBy;

        //for testing purposes
        page.value = 1;
        pageSize.value = 10;
    }

    total.value = batteries.value.length;
    pageCount.value = Math.ceil(total.value / pageSize.value);

    isLoading.value = false;

    // async function init() {
    //     isLoading.value = true;
    //     errorMessage.value = '';

    //     try {
    //         const { data } = await getAssetTotal();
    //         console.log('testr', data.meta.pagination.total);
    //         total_assets.value = data.meta.pagination.total;
    //     } catch (error) {
    //         errorMessage.value = error.message;
    //     } finally {
    //         isLoading.value = false;
    //     }
    // }

    // init();
</script>

<style scoped></style>

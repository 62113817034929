<template>
    <VChart
        v-if="props.chart != 'table'"
        ref="chart"
        :options="$chart.options.value"
        autoresize />
    <v-data-table
        v-else
        id="chartTable"
        v-bind="$chart.options.value"
        hover
        density="comfortable"
        fixed-header>
    </v-data-table>
</template>

<script setup lang="ts">
    import { ResultSet } from '@cubejs-client/core';
    import VChart from 'vue-echarts';
    import { useChart } from './composables/useChart';
    import { ChartType } from './composables/charts';
    import { ref, watchEffect } from 'vue';
    import { ECharts } from 'echarts';

    const props = defineProps<{
        resultSet: ResultSet;
        chart: ChartType;
    }>();

    const chart = ref<ECharts>();

    const $chart = useChart();

    watchEffect(() => {
        $chart.setResultSet(props.resultSet);
        $chart.setChartType(props.chart);
        if (chart.value) {
            chart.value.clear();
            chart.value.setOption($chart.options.value);
        }
    });
</script>

<style>
    x-vue-echarts {
        min-height: 300px;
    }
</style>

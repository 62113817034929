import { APIResponseMany, APIResponseSingle } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import { APIRequestParams } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/suppliers';

export async function getSuppliers(query: APIRequestParams<'Supplier'> = {}): AxiosPromise<APIResponseMany<'Supplier'>> {
    if (!_.has(query, 'filters.companies.id.$in')) _.set(query, 'filters.companies.id.$in', useAuthStore().getUserCompaniesIds);
    // _.set(query, 'filters.company.id.$in', useAuthStore().getUserCompaniesIds);
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getSupplier(id: number, query = {}): AxiosPromise<APIResponseSingle<'Supplier'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export default {
    getSuppliers,
    getSupplier,
};

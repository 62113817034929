import { APIResponseMany, APIResponseSingle } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import { APIRequestParams } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

const API_URL = '/models';
export const API_REF = 'api::model.model';

export async function getModels(query: APIRequestParams<'Model'> = {}): AxiosPromise<APIResponseMany<'Model'>> {
    if (!_.has(query, 'filters.companies.id.$in')) _.set(query, 'filters.companies.id.$in', useAuthStore().getUserCompaniesIds);

    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getModel(id: number, query = {}): AxiosPromise<APIResponseSingle<'Model'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export async function createModel(data: ContentType<'Model'>): AxiosPromise<APIResponseSingle<'Model'>> {
    return axios.post(API_URL, { data });
}

export async function updateModel(id: number, data: ContentType<'Model'>, query = {}): AxiosPromise<APIResponseSingle<'Model'>> {
    const searchParams = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${searchParams}`, { data });
}

export async function deleteModels(ids: number[]): AxiosPromise<APIResponseMany<'Model'>> {
    const query = qs.stringify({
        filters: {
            id: {
                $in: ids,
            }
        }
    })
    return axios.delete(`${API_URL}/many?${query}`);
}
export async function deleteModel(id: number): AxiosPromise<APIResponseSingle<'Model'>> {
    return axios.delete(`${API_URL}/${id}`);
}

export default {
    getModels,
    createModel,
    getModel,
    updateModel,
    deleteModels,
    deleteModel
};

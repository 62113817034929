<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('assets-management.discovery.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <div
            v-if="errorMessage"
            class="">
            <v-alert
                elevation="0"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row class="tw-py-4">
            <v-col
                cols="8"
                md="9"
                lg="10">
                <v-text-field
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('shared.search')"
                    v-model="search">
                    <template #append-inner>
                        <v-icon class="tw-text-gray">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </v-col>
            <v-col
                cols="4"
                md="3"
                lg="2">
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10 tw-w-full"
                    rounded="lg"
                    color="gray-300"
                    :to="{ name: 'AM-CreateAsset' }"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-black">mdi-filter</v-icon>
                    {{ $t('shared.filters') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table-server
            hover
            color="primary"
            density="comfortable"
            :loading="isLoading"
            :loading-text="$t('shared.loading')"
            :headers="headers"
            :items-per-page-options="[10, 25, 50, 100]"
            v-model:items-per-page="pageSize"
            :no-data-text="$t('assets-management.assets.errors.not_found')"
            v-model:page="page"
            :items-length="total"
            show-select
            @update:options="onOptionsUpdated"
            v-model="itemsSelected"
            :items="assets"
            height="calc(100vh - 280px)"
            :fixed-header="true">
            <template #item.attributes.icon="{ value }">
                <v-icon class="tw-text-2xl tw-text-gray-600">{{ value ? value?.replace(/:/g, '-') : '' }}</v-icon>
            </template>
            <template #item.actions="{ item }">
                <div class="tw-flex tw-gap-1">
                    <v-btn
                        color="green-600"
                        icon
                        size="x-small"
                        class="tw-rounded-md"
                        :to="{
                            name: 'AM-CreateDiscovery',
                            params: { name: item.name, entityId: item.id },
                        }">
                        <v-icon class="tw-text-base tw-text-white">mdi-plus</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
    </div>
    <ConfirmDelete
        v-model="isDeleting"
        @action="onDelete" />
</template>

<script setup lang="ts">
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { ContentType } from '@/contentTypes';
    import { ref, computed } from 'vue';
    import { getAssets, deleteAssets, deleteAsset } from '@/views/AssetsManagement/composables/assets';
    import { VDataTableOptions } from '@/types';
    import { getErrorKey } from '@/composables/error';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import ConfirmDelete from '@/components/ConfirmDelete.vue';

    const useAuth = useAuthStore();
    const user = useAuth.user;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('assets-management.title'),
            },
            {
                title: $t('assets-management.discovery.title'),
                to: { name: 'AM-Discoveries' },
            },
        ];
    });

    const $t = useI18n().t;

    const search = ref('');

    const assets = ref<ContentType<'Discovery'>[]>([]);
    const itemsSelected = ref<number[]>([]);
    const itemToDelete = ref<number | null>(null);
    const isLoading = ref(true);
    const pageCount = ref(0);
    const pageSize = ref(10);
    const errorMessage = ref('');
    const total = ref(0);
    const page = ref(1);
    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'name',
            order: 'asc',
        },
    ]);
    const firstUpdate = ref(true);
    const isDeleting = ref(false);

    const actionOptions = [
        {
            text: $t('shared.delete'),
            value: 'delete',
        },
    ];
    const actionSelected = ref('');

    const headers = [
        {
            title: $t('assets-management.discovery.fields.name'),
            sortable: true,
            key: 'name',
        },
        {
            title: $t('assets-management.discovery.fields.model'),
            sortable: true,
            key: 'model',
        },
        {
            title: $t('assets-management.discovery.fields.manufacturer'),
            sortable: true,
            key: 'manufacturer',
        },
        {
            title: $t('shared.actions'),
            sortable: false,
            key: 'actions',
        },
    ] as VDataTableServer['headers'];

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        page.value = options.page;
        pageSize.value = options.itemsPerPage;

        sort.value = options.sortBy.length > 0 ? options.sortBy : sort.value;
        init();
    }

    async function init() {
        isLoading.value = true;
        errorMessage.value = '';
        try {
            const { data } = await getAssets({
                discovery: true,
                sortBy: sort.value.map((s) => `${s.key}:${s.order}`),
                pagination: {
                    page: page.value,
                    pageSize: pageSize.value,
                },
            });
            assets.value = data.data;
            total.value = data.meta.pagination.total;
            pageCount.value = data.meta.pagination.pageCount;
        } catch (error) {
            const messageKey = getErrorKey(error, `assets-management.discovery.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            isLoading.value = false;
        }
    }

    function executeAction(id: any) {
        if (id) (isDeleting.value = true), (itemToDelete.value = assets.value.find((a) => a.id === id)?.id);
        if (!id) {
            if (actionSelected.value === 'delete') isDeleting.value = true;
        }
    }
    async function onDelete(action: string) {
        if (action !== 'yes') return;
        isLoading.value = true;
        try {
            if (itemToDelete.value) await deleteAsset(itemToDelete.value);
            if (itemsSelected.value.length > 0) await deleteAssets(itemsSelected.value);
            init();
        } catch (error) {
            const messageKey = getErrorKey(error, `assets-management.discovery.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            itemToDelete.value = null;
            isLoading.value = false;
        }
    }

    init();
</script>

<style></style>

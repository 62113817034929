<template>
    <v-row>
        <v-col>
            <div class="tw-flex tw-items-center tw-gap-x-2">
                <v-icon class="tw-rounded-md tw-bg-primary-100 tw-bg-opacity-100 tw-p-5 tw-text-primary-600">mdi-map-marker</v-icon>
                <p class="tw-text-sm tw-font-semibold">{{ matrix.location }}</p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <v-icon
                    class="tw-text-xl"
                    @click="handleExpandedState"
                    >{{ matrix.expanded == false ? 'mdi-arrow-expand' : 'mdi-arrow-collapse' }}</v-icon
                >
                <v-icon @click="removeMatrix">mdi-close</v-icon>
            </div>
        </v-col>
    </v-row>
    <!-- First Row -->
    <v-row>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Indic.</p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 24"
                    class="tw-w-full tw-text-center">
                    {{ i }}
                </p>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Alertas</p>
            </div>
        </v-col>
    </v-row>
    <!-- Next Rows (based on the number of indicators)-->
    <v-row class="tw-mt-0">
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Eletricidade</p>
            </div>
        </v-col>
        <v-col>
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 24"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                    <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col cols="2">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-red-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row class="tw-mt-0">
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Água</p>
            </div>
        </v-col>
        <v-col class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 24"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                    <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-green-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row class="tw-mt-0">
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-rounded-md tw-bg-gray-200 tw-text-center tw-text-xs tw-font-semibold">
                <p class="tw-overflow-hidden tw-text-ellipsis tw-px-1">Ar Comprimido</p>
            </div>
        </v-col>
        <v-col class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-items-center tw-justify-center tw-gap-x-1 tw-rounded-md tw-bg-gray-200 tw-text-xs tw-font-semibold">
                <p
                    v-for="i in 24"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-sm"
                    :class="generateRandomColor()">
                    <span v-if="!matrix.expanded">&nbsp;</span>
                    <span v-if="matrix.expanded"><p class="tw-text-[10px] tw-text-white">00:00</p></span>
                </p>
            </div>
        </v-col>
        <v-col
            cols="2"
            class="tw-pt-1">
            <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
                <div class="tw-flex tw-gap-x-1">
                    <p class="tw-rounded-sm tw-bg-yellow-500 tw-p-[.125rem] tw-text-center tw-text-white tw-opacity-90">00</p>
                    <p class="tw-rounded-sm tw-bg-white tw-p-[.125rem] tw-text-center tw-text-gray-600">00:00</p>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <div class="tw-rounded-md tw-bg-gray-200">
                <div class="tw-flex tw-items-center tw-gap-x-2 tw-p-2">
                    <v-icon class="tw-rounded-md tw-bg-primary-100 tw-bg-opacity-100 tw-p-4 tw-text-primary-600">mdi-chart-bar</v-icon>
                    <p>EasyHan EB1 TEI</p>
                </div>
                <Chart
                    class="chart"
                    type="bar"
                    :data="[0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.2, 0.3, 1.23, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 1.8]"
                    :xAxisInfo="[
                        '00:00',
                        '01:00',
                        '02:00',
                        '03:00',
                        '04:00',
                        '05:00',
                        '06:00',
                        '07:00',
                        '08:00',
                        '09:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ]"
                    series_name="kWh"
                    :series_color="primaryColor" />
            </div>
        </v-col>
        <!-- <div class="tw-flex tw-h-7 tw-w-full tw-items-center tw-justify-center tw-overflow-hidden tw-text-ellipsis tw-rounded-md tw-bg-gray-200 tw-text-xs">
            <p>asdsaasd</p>
        </div> -->
    </v-row>
</template>
<script setup lang="ts">
    import Chart from '@/components/charts/Chart.vue';
    import { useAuthStore } from '@/store/auth';

    const primaryColor = useAuthStore().getPrimaryColor;

    const generateRandomColor = () => {
        const colors = ['tw-bg-red-400 tw-opacity-70', 'tw-bg-green tw-opacity-50', 'tw-bg-yellow tw-opacity-50'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const props = defineProps({
        matrix: {
            type: Object,
            required: true,
        },
    });
    const emit = defineEmits(['update:state', 'update:remove']);

    const handleExpandedState = () => {
        props.matrix.expanded = !props.matrix.expanded;
        emit('update:state', { ...props.matrix });
    };

    const removeMatrix = () => {
        emit('update:remove', props.matrix);
    };
</script>

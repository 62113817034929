import { isArray } from "lodash";

export default function useIconDictionary() {
    return {
        getIconAndClass(entityId: string | string[]) {
            if (isArray(entityId)) entityId = entityId[0];
            if (entityId.includes('temperature')) {
                return {
                    icon: 'mdi-thermometer',
                    class: 'tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-5 tw-text-red-500'
                };
            }
            if (entityId.includes('humidity')) {
                return {
                    icon: 'mdi-water-percent',
                    class: 'tw-rounded-md tw-bg-green-100 tw-bg-opacity-100 tw-p-5 tw-text-green-500'

                };
            }
            if (entityId.includes('battery')) {
                return {
                    icon: 'mdi-battery',
                    class: 'tw-rounded-md tw-bg-blue-100 tw-bg-opacity-100 tw-p-5 tw-text-blue-500'
                };
            }
            if (entityId.includes('ph')) {
                return {
                    icon: 'mdi-ph',
                    class: 'tw-rounded-md tw-bg-purple-100 tw-bg-opacity-100 tw-p-5 tw-text-purple-500'
                };
            }
            if (entityId.includes('power')) {
                return {
                    icon: 'mdi-flash',
                    class: 'tw-rounded-md tw-bg-yellow-100 tw-bg-opacity-100 tw-p-5 tw-text-yellow-500'
                };
            }
            if (entityId.includes('bill')) {
                return {
                    icon: 'mdi-cash-multiple',
                    class: 'tw-rounded-md tw-bg-green-100 tw-bg-opacity-100 tw-p-5 tw-text-green-500'
                };
            }
            if (entityId.includes('co2_intensity')) {
                return {
                    icon: 'mdi-molecule-co2',
                    class: 'tw-rounded-md tw-bg-orange-100 tw-bg-opacity-100 tw-p-5 tw-text-orange-500'
                };
            }
            if (entityId.includes('fossil')) {
                return {
                    icon: 'mdi-barrel',
                    class: 'tw-rounded-md tw-bg-blue-100 tw-bg-opacity-100 tw-p-5 tw-text-blue-500'
                };
            }
            else {
                return {
                    icon: 'mdi-help',
                    class: 'tw-rounded-md tw-bg-gray-100 tw-bg-opacity-100 tw-p-5 tw-text-gray-500'
                };
            }
        }
    };
}
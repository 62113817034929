<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('control.hvac.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col
                v-for="hvac in hvacs"
                class="mx-auto">
                <v-card variant="flat"
                    class="mx-auto tw-h-full tw-p-4"
                    >
                    <v-row>
                        <v-col cols="12">
                            <v-card-subtitle> {{ hvac.location }} </v-card-subtitle>
                        </v-col>
                        <v-card-text>
                            <div class="tw-flex tw-items-center tw-justify-center tw-gap-x-16">
                                <div>
                                    <div class="tw-mb-1 tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-4 tw-text-green-500"> mdi-home-thermometer </v-icon>
                                        <p>{{ hvac.locationTemperature }} °C</p>
                                    </div>
                                    <div class="tw-mb-1 tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-blue-200 tw-bg-opacity-100 tw-p-4 tw-text-blue-500">mdi-water-percent</v-icon>
                                        <p>{{ hvac.humidity }} %</p>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-red-200 tw-bg-opacity-100 tw-p-4 tw-text-red-500">mdi-thermometer-lines</v-icon>
                                        <p>{{ hvac.status }}</p>
                                    </div>
                                </div>
                                <div class="tw-text-center">
                                    <v-icon @click="handleTemperature('up', hvac.id)">mdi-chevron-up</v-icon>
                                    <p class="tw-text-4xl tw-font-semibold">{{ hvac.temperature }} °C</p>
                                    <v-icon @click="handleTemperature('down', hvac.id)">mdi-chevron-down</v-icon>
                                </div>
                            </div>
                            <div class="tw-mt-8 tw-flex tw-justify-center tw-gap-x-2">
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-power"
                                    flat
                                    :color="hvac.isOffActive ? 'primary' : 'gray-200'"
                                    size="large"
                                    @click="toggleState('off', hvac.id)">
                                    <p>off</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-snowflake"
                                    flat
                                    :color="hvac.isCoolActive ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('cool', hvac.id)">
                                    <p>cool</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-water-percent"
                                    flat
                                    :color="hvac.isDryActive ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('dry', hvac.id)">
                                    <p>dry</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-fan"
                                    flat
                                    :color="hvac.isFanOnlyActive ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('fanOnly', hvac.id)">
                                    <p>fan_only</p>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('control.title'),
            },
            {
                title: $t('control.hvac.title'),
                to: { name: 'Hvac' },
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const hvacs = ref([
        {
            id: 1,
            temperature: 18,
            humidity: 65.13,
            status: 'increasing',
            location: 'Local 1',
            locationTemperature: 30.04,
            isOffActive: true,
            isCoolActive: false,
            isDryActive: false,
            isFanOnlyActive: false,
        },
        {
            id: 2,
            temperature: 18,
            humidity: 60,
            status: 'decreasing',
            location: 'Local 2',
            locationTemperature: 29,
            isOffActive: true,
            isCoolActive: false,
            isDryActive: false,
            isFanOnlyActive: false,
        },
        {
            id: 3,
            temperature: 18,
            humidity: 55,
            status: 'increasing',
            location: 'Local 3',
            locationTemperature: 28,
            isOffActive: true,
            isCoolActive: false,
            isDryActive: false,
            isFanOnlyActive: false,
        },
    ]);

    const toggleState = (state: string, hvacId: number) => {
        const hvac = hvacs.value.find((hvac) => hvac.id === hvacId);
        hvac.isOffActive = state === 'off';
        hvac.isCoolActive = state === 'cool';
        hvac.isDryActive = state === 'dry';
        hvac.isFanOnlyActive = state === 'fanOnly';

        if (state === 'off') hvac.temperature = 18;
    };

    const handleTemperature = (action: string, hvacId: number) => {
        const hvac = hvacs.value.find((hvac) => hvac.id === hvacId);
        if (action === 'up') {
            hvac.temperature += 1;
        } else {
            hvac.temperature -= 1;
        }
    };
</script>
<style></style>

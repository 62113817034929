import _ from 'lodash';

const usePieChart = (_options = {}) => {
    const options = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '5%',
            left: 'center',
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false,
        },
        series: [
            {
                name: 'Origem do consumo',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '70%'],
                // adjust the start angle
                startAngle: 180,
                // label: {
                //     show: true,
                //     formatter(param) {
                //         // correct the percentage
                //         return param.name + ' (' + param.percent! * 2 + '%)';
                //     },
                // },
                data: [
                    { value: 82, name: 'Low-carbon energy consumed', itemStyle: { color: '#00b050' }},
                    { value: 18, name: '', itemStyle: { color: '#ff0000' }},
                    {
                        // make an record to fill the bottom 50%
                        value: 82 + 18,
                        itemStyle: {
                            // stop the chart from rendering this piece
                            color: 'none',
                            decal: {
                                symbol: 'none',
                            },
                        },
                        label: {
                            show: false,
                        },
                    },
                ],
            },
        ],
    };
    return {
        options,
    };
};

export default usePieChart;

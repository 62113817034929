import { area } from './area';
import { bar } from './bar';
import { barside } from './barside';
import { doughnut } from './doughnut';
import { gauge } from './gauge';
import { heatmap } from './heatmap';
import { line } from './line';
import { pie } from './pie';
import { radar } from './radar';
import { scatter } from './scatter';
import { table } from './table';

const charts = {
    // area,
    bar,
    // barside,
    // doughnut,
    // gauge,
    // heatmap,
    line,
    // pie,
    // radar,
    // scatter,
    table,
};

export default charts;

export const chartTypes = Object.keys(charts) as ChartType[];

export type ChartType = keyof typeof charts;

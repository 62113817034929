// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

// ASSETS MANAGEMENT
// ASSETS
import AMAssets from '@/views/AssetsManagement/Assets/Assets.vue';
import AMFormAsset from '@/views/AssetsManagement/Assets/FormAsset.vue';
import AMAsset from '@/views/AssetsManagement/Assets/Asset.vue';
//DISCOVERY
import AMDiscoveries from '@/views/AssetsManagement/Discovery/Discoveries.vue';
import AMFormDiscovery from '@/views/AssetsManagement/Discovery/FormDiscovery.vue';
import AMDiscovery from '@/views/AssetsManagement/Discovery/Discovery.vue';
// ASSET MODELS
import AMModels from '@/views/AssetsManagement/Models/Models.vue';
import AMFormModel from '@/views/AssetsManagement/Models/FormModel.vue';
import AMModel from '@/views/AssetsManagement/Models/Model.vue';
// STATUS LABELS
import AMStatusLabels from '@/views/AssetsManagement/StatusLabels/StatusLabels.vue';
import AMFormStatusLabel from '@/views/AssetsManagement/StatusLabels/FormStatusLabel.vue';
import AMStatusLabel from '@/views/AssetsManagement/StatusLabels/StatusLabel.vue';
// CATEGORIES
import AMCategories from '@/views/AssetsManagement/Categories/Categories.vue';
import AMFormCategory from '@/views/AssetsManagement/Categories/FormCategory.vue';
import AMCategory from '@/views/AssetsManagement/Categories/Category.vue';

// DASHBOARDS
import AssetsDashboard from '@/views/Dashboards/Assets.vue';
import EnvironmentDashboard from '@/views/Dashboards/Environment.vue';
import WaterDashboard from '@/views/Dashboards/Water.vue';
import EnergyDashboard from '@/views/Dashboards/Energy.vue';
import MatrixDashboard from '@/views/Dashboards/Matrix.vue';
import IndustryDashboard from '@/views/Dashboards/Industry.vue';

//ALARMIST
import EnergyAlarmist from '@/views/Alarmist/Energy.vue';
import WaterAlarmist from '@/views/Alarmist/Water.vue';
import EnvironmentAlarmist from '@/views/Alarmist/Environment.vue';

//SETTINGS
import Alarmist from '@/views/Settings/Alarmist.vue';
import FormAlarmist from '@/views/Settings/FormAlarmist.vue';

// INTELLIGENCE
// CUBE
import DCMain from '@/views/Intelligence/Cube/Main.vue';
// ANALYTICS
import Analytics from '@/views/Intelligence/Analytics/Analytics.vue';

//CONTROL
//HVAC
import Hvac from '@/views/Control/Hvac.vue';

//INDUSTRY
//CONTROL
//DYEING
import Dyeing from '@/views/Industry/Control/Dyeing.vue';
//ROLLING MILLS
import RollingMills from '@/views/Industry/Control/RollingMills.vue';
//LAUNDRY
import Laundry from '@/views/Industry/Control/Laundry.vue';
//PROCESSES
import Processes from '@/views/Industry/Processes.vue';

//NOT FOUND
import NotFound from '@/views/NotFound/NotFound.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    // create 404 page
    {
        path: '/',
        component: Home,
        name: 'Home',
        redirect: {
            name: 'EnergyDashboard',
        },
        meta: {
            authRequired: true
        },
        children: [
            //path not found
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: NotFound,
                meta: {
                    authRequired: false,
                },
            },
            //assets management
            {
                path: 'assets-management',
                name: 'AssetsManagement',
                redirect: {
                    name: 'AM-Assets',
                },
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'assets',
                        children: [
                            {
                                path: '',
                                name: 'AM-Assets',
                                component: AMAssets,
                            },
                            {
                                path: 'create',
                                name: 'AM-CreateAsset',
                                component: AMFormAsset,
                            },
                            {
                                path: ':id',
                                name: 'AM-Asset',
                                component: AMAsset,
                            },
                            {
                                path: ':id/edit',
                                name: 'AM-EditAsset',
                                component: AMFormAsset,
                                meta: {
                                    breadcrumbs: false,
                                },
                            },
                            {
                                path: ':id/clone',
                                name: 'AM-CloneAsset',
                                component: AMFormAsset,
                            },
                        ],
                    },
                    {
                        path: 'discovery',
                        children: [
                            {
                                path: '',
                                name: 'AM-Discoveries',
                                component: AMDiscoveries,
                            },
                            {
                                path: 'create?name=:name&entityId=:entityId',
                                name: 'AM-CreateDiscovery',
                                component: AMFormDiscovery,
                            },
                            {
                                path: ':id',
                                name: 'AM-Discovery',
                                component: AMDiscovery,
                            },
                            {
                                path: ':id/edit',
                                name: 'AM-EditDiscovery',
                                component: AMFormDiscovery,
                            },
                            {
                                path: ':id/clone',
                                name: 'AM-CloneDiscovery',
                                component: AMFormDiscovery,
                            },
                        ],
                    },
                ],
            },
            //dashboards
            {
                path: 'dashboards',
                name: 'Dashboards',
                redirect: {
                    name: 'EnergyDashboard',
                },
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'assets-dashboard',
                        name: 'AssetsDashboard',
                        component: AssetsDashboard,
                        meta: {
                            permissions: ['assets'],
                        },
                    },
                    {
                        path: 'energy-dashboard',
                        name: 'EnergyDashboard',
                        component: EnergyDashboard,
                        meta: {
                            permissions: ['energy'],
                        },
                    },
                    // {
                    //     path: 'environment-dashboard',
                    //     name: 'EnvironmentDashboard',
                    //     component: EnvironmentDashboard,
                    //     meta: {
                    //         permissions: ['environment'],
                    //     },
                    // },
                    {
                        path: 'water-dashboard',
                        name: 'WaterDashboard',
                        component: WaterDashboard,
                        meta: {
                            permissions: ['water'],
                        },
                    },
                    {
                        path: 'matrix-dashboard',
                        name: 'MatrixDashboard',
                        component: MatrixDashboard,
                        meta: {
                            permissions: ['assets', 'energy', 'environment', 'water'],
                        },
                    },
                    // {
                    //     path: 'industry-dashboard',
                    //     name: 'IndustryDashboard',
                    //     component: IndustryDashboard,
                    //     meta: {
                    //         permissions: ['assets', 'energy', 'environment', 'water'],
                    //     },
                    // },
                ],
            },
            //alarmist
            {
                path: 'alarmist',
                name: 'Alarmist',
                redirect: {
                    name: 'EnergyAlarmist',
                },
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'energy-alarmist',
                        name: 'EnergyAlarmist',
                        component: EnergyAlarmist,
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'water-alarmist',
                        name: 'WaterAlarmist',
                        component: WaterAlarmist,
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'environment-alarmist',
                        name: 'EnvironmentAlarmist',
                        component: EnvironmentAlarmist,
                        meta: {
                            authRequired: true,
                        },
                    }
                ]
            },
            //intelligence
            {
                path: 'intelligence',
                name: 'Intelligence',
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'dashboards',
                        name: 'DC-Dashboards',
                        component: DCMain,
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'analytics',
                        name: 'Analytics',
                        component: Analytics,
                        meta: {
                            authRequired: true,
                        },
                    },
                ],
            },
            //control
            {
                path: 'control',
                name: 'Control',
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'hvac',
                        name: 'Hvac',
                        component: Hvac,
                        meta: {
                            authRequired: true,
                        },
                    },
                ],
            },
            //industry
            {
                path: 'industry',
                redirect: {
                    name: 'Dyeing',
                },
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'control',
                        name: 'IndustryControl',
                        meta: {
                            authRequired: true,
                        },
                        children: [
                            {
                                path: 'dyeing',
                                name: 'Dyeing',
                                component: Dyeing,
                                meta: {
                                    authRequired: true,
                                },
                            },
                            {
                                path: 'rolling-mills',
                                name: 'RollingMills',
                                component: RollingMills,
                                meta: {
                                    authRequired: true,
                                },
                            },
                            {
                                path: 'laundry',
                                name: 'Laundry',
                                component: Laundry,
                                meta: {
                                    authRequired: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'processes',
                        name: 'Processes',
                        component: Processes,
                        meta: {
                            authRequired: true,
                        },
                    }
                ]
            },
            {
                path: 'settings',
                redirect: {
                    name: 'AlarmistSettings',
                },
                meta: {
                    authRequired: true,
                },
                children: [
                    {
                        path: 'assets',
                        children: [
                            // {
                            //     path: 'status-labels',
                            //     children: [
                            //         {
                            //             path: '',
                            //             name: 'AM-StatusLabels',
                            //             component: AMStatusLabels,
                            //         },
                            //         {
                            //             path: 'create',
                            //             name: 'AM-CreateStatusLabel',
                            //             component: AMFormStatusLabel,
                            //         },
                            //         {
                            //             path: ':id',
                            //             name: 'AM-StatusLabel',
                            //             component: AMStatusLabel,
                            //         },
                            //         {
                            //             path: ':id/edit',
                            //             name: 'AM-EditStatusLabel',
                            //             component: AMFormStatusLabel,
                            //         },
                            //     ],
                            // },
                            // {
                            //     path: 'asset-models',
                            //     children: [
                            //         {
                            //             path: '',
                            //             name: 'AM-Models',
                            //             component: AMModels,
                            //         },
                            //         {
                            //             path: 'create',
                            //             name: 'AM-CreateModel',
                            //             component: AMFormModel,
                            //         },
                            //         {
                            //             path: ':id',
                            //             name: 'AM-Model',
                            //             component: AMModel,
                            //         },
                            //         {
                            //             path: ':id/edit',
                            //             name: 'AM-EditModel',
                            //             component: AMFormModel,
                            //         },
                            //         {
                            //             path: ':id/clone',
                            //             name: 'AM-CloneModel',
                            //             component: AMFormModel,
                            //         },
                            //     ],
                            // },
                            // {
                            //     path: 'categories',
                            //     children: [
                            //         {
                            //             path: '',
                            //             name: 'AM-Categories',
                            //             component: AMCategories,
                            //         },
                            //         {
                            //             path: 'create',
                            //             name: 'AM-CreateCategory',
                            //             component: AMFormCategory,
                            //         },
                            //         {
                            //             path: ':id',
                            //             name: 'AM-Category',
                            //             component: AMCategory,
                            //         },
                            //         {
                            //             path: ':id/edit',
                            //             name: 'AM-EditCategory',
                            //             component: AMFormCategory,
                            //         },
                            //     ],
                            // },
                        ],
                    },
                    {
                        path: 'companies',
                        children: [
                            {
                                path: 'departments',
                                name: 'Departments',
                                //component: AMAssets,
                            },
                            {
                                path: 'sections',
                                name: 'Sections',
                                //component: AMAssets,
                            },
                            {
                                path: 'locations',
                                name: 'Locations',
                                //component: AMAssets,
                            }
                        ]
                    },
                    {
                        path: 'alarmist',
                        children: [
                            {
                                path: '',
                                name: 'AlarmistSettings',
                                component: Alarmist,
                            },
                            {
                                path: 'create',
                                name: 'CreateAlarmist',
                                component: FormAlarmist,
                            },
                            {
                                path: ':id/edit',
                                name: 'EditAlarmist',
                                component: FormAlarmist,
                            }
                        ]
                    },

                ]
            }
        ],
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    next();
    const authRequired = to.matched.some((route) => route.meta.authRequired);
    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            const permissions = to.matched.map((route) => (route.meta.permissions ? route.meta.permissions : [])).flat();

            console.log('permissions', permissions);

            if (permissions.length > 0) {
                const authStore = useAuthStore();

                const hasPermission = permissions.every((permission) => authStore.hasPermission(permission as string));

                if (hasPermission) {
                    next();
                } else {
                    next({ name: 'NotFound' });
                }
            } else {
                next();
            }
        }
    } else next();
});

export default router;

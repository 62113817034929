<template>
    <v-img
        class="io-img"
        :src="useMedia(props.media, props.contentType)[props.format]"
        v-bind="vImgProps">
        <template #error>
            <v-img
                contain
                alt=""
                class=""
                :src="getPlaceholderImage(props.contentType)">
            </v-img>
        </template>
    </v-img>
</template>

<script setup lang="ts">
    import { getPlaceholderImage, useMedia } from '@/composables/useMedia';
    import { ContentTypes, Media } from '../contentTypes';
    import { computed } from 'vue';
    import { omit } from 'lodash';

    const props = defineProps<{
        media: Media | null;
        contentType: keyof ContentTypes;
        format: keyof Media['formats'];
    }>();

    const vImgProps = computed(() => omit(props, ['media', 'contentType', 'format']));
</script>

<style>
    .io-img {
        @apply tw-m-1 tw-border-spacing-4 tw-rounded-xl tw-border tw-border-gray-400;
        .v-img__img {
            @apply tw-p-3;
        }
    }
</style>

import { useI18n } from 'vue-i18n';

export function useMenu() {
    const $t = useI18n().t;
    return [
        //dashboards
        {
            id: 'dashboards',
            label: $t('dashboards.title'),
            icon: 'mdi-monitor-dashboard',
            children: [
                {
                    id: 'assets-dashboard',
                    label: $t('dashboards.assets.title'),
                    icon: 'mdi-access-point',
                    to: {
                        name: 'AssetsDashboard',
                    },
                },
                {
                    id: 'energy-dashboard',
                    label: $t('dashboards.energy.title'),
                    icon: 'mdi-lightning-bolt',
                    to: {
                        name: 'EnergyDashboard',
                    },
                },
                // {
                //     id: 'environment-dashboard',
                //     label: $t('dashboards.environment.title'),
                //     icon: 'mdi-leaf',
                //     to: {
                //         name: 'EnvironmentDashboard',
                //     },
                // },
                {
                    id: 'water-dashboard',
                    label: $t('dashboards.water.title'),
                    icon: 'mdi-water',
                    to: {
                        name: 'WaterDashboard',
                    },
                },
                {
                    id: 'matrix-dashboard',
                    label: $t('dashboards.matrix.title'),
                    icon: 'mdi-view-dashboard',
                    to: {
                        name: 'MatrixDashboard',
                    }
                },
                // {
                //     id: 'industry-dashboard',
                //     label: $t('dashboards.industry.title'),
                //     icon: 'mdi-factory',
                //     to: {
                //         name: 'IndustryDashboard',
                //     }
                // }
            ],
        },
        //alarmist
        {
            id: 'alarmist',
            label: $t('alarmist.title'),
            icon: 'mdi-alarm-light',
            children: [
                {
                    id: 'energy-alarmist',
                    label: $t('dashboards.energy.title'),
                    icon: 'mdi-lightning-bolt',
                    to: {
                        name: 'EnergyAlarmist',
                    },
                },
                // {
                //     id: 'environment-alarmist',
                //     label: $t('dashboards.environment.title'),
                //     icon: 'mdi-leaf',
                //     to: {
                //         name: 'EnvironmentAlarmist',
                //     },
                // },
                {
                    id: 'water-alarmist',
                    label: $t('dashboards.water.title'),
                    icon: 'mdi-water',
                    to: {
                        name: 'WaterAlarmist',
                    },
                },
            ],
        },
        //intelligence
        {
            id: 'intelligence',
            label: $t('intelligence.title'),
            icon: 'mdi-brain',
            children: [
                {
                    id: 'dc-dashboards',
                    label: $t('intelligence.consultations.title'),
                    icon: 'mdi-monitor-dashboard',
                    to: {
                        name: 'DC-Dashboards',
                    },
                },
                {
                    id: 'analytics',
                    label: $t('intelligence.analytics.title'),
                    icon: 'mdi-google-analytics',
                    to: {
                        name: 'Analytics',
                    },
                },
            ],
        },
        //control
        {
            id: 'control',
            label: $t('control.title'),
            icon: 'mdi-gesture-tap',
            children: [
                {
                    id: 'hvac',
                    label: $t('control.hvac.title'),
                    icon: 'mdi-air-conditioner',
                    to: {
                        name: 'Hvac',
                    },
                },
            ]
        },
        //industry
        {
            id: 'industry',
            label: $t('industry.title'),
            icon: 'mdi-factory',
            children: [
                {
                    id: 'industry-control',
                    label: $t('industry.control.title'),
                    icon: 'mdi-gesture-tap',
                    children: [
                        {
                            id: 'dyeing',
                            label: $t('industry.control.dyeing.title'),
                            icon: 'mdi-palette',
                            to: {
                                name: 'Dyeing',
                            },
                        },
                        {
                            id: 'rolling-mills',
                            label: $t('industry.control.rollingMills.title'),
                            icon: 'mdi-factory',
                            to: {
                                name: 'RollingMills',
                            },
                        },
                        {
                            id: 'laundry',
                            label: $t('industry.control.laundry.title'),
                            icon: 'mdi-washing-machine',
                            to: {
                                name: 'Laundry',
                            },
                        }
                    ]
                },
                {
                    id: 'industry-processes',
                    label: $t('industry.processes.title'),
                    icon: 'mdi-cog-sync',
                    to: {
                        name: 'Processes'
                    }
                }
            ]
        },
        //assets management
        {
            id: 'assets-management',
            label: $t('assets-management.title'),
            icon: 'mdi-access-point',

            children: [
                {
                    id: 'assets',
                    label: $t('assets-management.assets.title'),
                    icon: 'mdi-barcode',
                    to: {
                        name: 'AM-Assets',
                    },
                },
                {
                    id: 'discovery',
                    label: $t('assets-management.discovery.title'),
                    icon: 'mdi-home-search',
                    to: {
                        name: 'AM-Discoveries',
                    },
                },
            ],
        },
        //settings
        {
            id: 'Configs',
            label: $t('configs.title'),
            icon: 'mdi-cog',
            children: [
                {
                    id: 'assets',
                    label: $t('configs.assets.title'),
                    icon: 'mdi-access-point',
                    children: [
                        // {
                        //     id: 'custom-fields',
                        //     label: $t('assets-management.custom-fields.title'),
                        //     icon: 'mdi-cog',
                        //     to: {
                        //         name: 'CustomFields',
                        //     },
                        // },

                        // {
                        //     id: 'status-labels',
                        //     label: $t('assets-management.status-labels.title'),
                        //     icon: 'mdi-cog',
                        //     to: {
                        //         name: 'AM-StatusLabels',
                        //     },
                        // },
                        // {
                        //     id: 'asset-models',
                        //     label: $t('assets-management.asset-models.title'),
                        //     icon: 'mdi-cog',
                        //     to: {
                        //         name: 'AM-Models',
                        //     },
                        // },

                        // {
                        //     id: 'categories',
                        //     label: $t('assets-management.categories.title'),
                        //     icon: 'mdi-cog',
                        //     to: {
                        //         name: 'AM-Categories',
                        //     },
                        // },
                        {
                            id: 'manufacturers',
                            label: $t('assets-management.manufacturers.title'),
                            icon: 'mdi-cog',
                            to: {
                                name: 'Manufacturers',
                            },
                        },

                        {
                            id: 'suppliers',
                            label: $t('assets-management.suppliers.title'),
                            icon: 'mdi-cog',
                            to: {
                                name: 'Suppliers',
                            },
                        },
                        // {
                        //     id: 'depreciation',
                        //     label: $t('assets-management.depreciation.title'),
                        //     icon: 'mdi-cog',
                        //     to: {
                        //         name: 'Depreciation',
                        //     },
                        // },
                    ],
                },
                {
                    id: 'companies',
                    label: $t('assets-management.companies.title'),
                    icon: 'mdi-domain',
                    // to: {
                    //     name: 'Companies',
                    // },
                    children: [
                        {
                            id: 'departments',
                            label: $t('assets-management.departments.title'),
                            icon: 'mdi-sitemap',
                            to: {
                                name: 'Departments',
                            },
                        },
                        {
                            id: 'sections',
                            label: $t('assets-management.sections.title'),
                            icon: 'mdi-view-dashboard-variant',
                            to: {
                                name: 'Sections',
                            },
                        },

                        {
                            id: 'locations',
                            label: $t('assets-management.locations.title'),
                            icon: 'mdi-office-building-marker',
                            to: {
                                name: 'Locations',
                            },
                        },
                    ],
                },
                {
                    id: 'alarmist',
                    label: $t('alarmist.title'),
                    icon: 'mdi-alarm-light',
                    to: {
                        name: 'AlarmistSettings',
                    },
                },
            ],
        },
    ];
}

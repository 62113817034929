import _ from 'lodash';
import { ResultSet, SeriesNamesColumn } from '@cubejs-client/core';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { dateToGranularity } from '../useChart';
import { TimeDimensionGranularity } from '../..';

export function line(resultSet: ResultSet, defaultOptions: EChartsOption = {}) {

    const granularity = _.get(resultSet.query(), 'timeDimensions[0].granularity') as TimeDimensionGranularity;
    const measures = _.get(resultSet.query(), 'measures', []).join(' / ');
    const dimensions = _.get(resultSet.query(), 'dimensions', []).join(' / ');
    const xAxis = resultSet.chartPivot().map((p) => dateToGranularity(p.x, granularity) as string);

    const series = resultSet.series().map((s) => {
        const serieName = resultSet.seriesNames().find((e) => e.key === s.key) as SeriesNamesColumn;

        return {
            name: serieName.yValues[0],
            type: 'line',
            smooth: true,
            data: s.series.map((e) => ({
                value: e.value,
                // itemStyle: {
                //     color: customColors.getColorByKey(s.key),
                // },
            })),
            // lineStyle: {
            //     color: customColors.getColorByKey(s.key),
            // },
            emphasis: {
                focus: 'series',
            },
            // hide numbers
            // label: {
            //     show: true,
            //     position: 'inside',
            // },
        } as LineSeriesOption
    });

    let options: EChartsOption = {
        legend: {
            data: resultSet.seriesNames().map((e) => ({
                name: e.yValues[0].toString(),
                // itemStyle: {
                //     color: customColors.getColorByKey(e.key),
                // },
            })),
        },
        xAxis: {
            type: 'category',
            data: xAxis,
            nameTextStyle: {
                fontSize: 15,
            },
            name: granularity || dimensions,
        },
        yAxis: {
            type: 'value',
            name: measures,
            nameLocation: 'middle',
            nameGap: 60,
        },
        tooltip: {
            trigger: 'axis',
        },
        series,
        dataZoom: [
            {
                type: 'slider',
            },
            {
                type: 'inside',
            },
        ],
    };


    return _.merge(options, defaultOptions);
};
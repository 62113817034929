<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('intelligence.consultations.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <QueryBuilder
        class="tw-mb-8"
        :loading="pageLoad"
        :result-set="resultSet"
        @load="onLoad"
        @clear="onClear" />

    <Chart
        v-if="resultSet"
        :result-set="resultSet"
        :chart="chart" />
</template>

<script setup lang="ts">
    import _ from 'lodash';
    import QueryBuilder from './QueryBuilder.vue';
    import Chart from './Chart.vue';
    import { useRoute } from 'vue-router';
    import { unflatten } from 'flat';
    import { useCube } from './composables/useCube';
    import { Query, ResultSet } from '@cubejs-client/core';
    import { computed, ref } from 'vue';
    import { ChartType, chartTypes } from './composables/charts';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';

    const { t } = useI18n();

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: t('intelligence.title'),
            },
            {
                title: t('intelligence.consultations.title'),
                to: { name: 'DC-Dashboards' },
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const $route = useRoute();
    const $cube = useCube();
    const $alert = useAlert();
    const isLoading = ref(false);

    const resultSet = ref<ResultSet>();
    const chart = ref<ChartType>('bar');

    async function onLoad() {
        const query = unflatten($route.query) as Query & { chart?: ChartType };
        chart.value = query.chart || chartTypes[0];
        delete query.chart;
        isLoading.value = true;
        try {
            resultSet.value = await $cube.cube.load(query);
        } catch (error: any) {
            $alert.showAlert({
                title: t('shared.errorOcurred'),
                text: _.get(error, 'response.error.message', _.get(error, 'message', t('shared.unknown_error'))),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }

    function onClear() {
        resultSet.value = undefined;
    }

    const pageLoad = computed(() => {
        return isLoading.value || $cube.isLoading.value;
    });
</script>

<style scoped></style>

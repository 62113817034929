import axios from '@axios';

const API_URL = '/upload';

export async function uploadFiles({ files, path, refId, ref, field }: { files: File[] | File; path?: string; refId?: number; ref?: string; field?: string }) {
    const formData = new FormData();

    if (!Array.isArray(files)) files = [files];

    files.forEach((file) => {
        formData.append('files', file);
    });

    if (path) formData.append('path', path);
    if (refId) formData.append('refId', refId.toString());
    if (ref) formData.append('ref', ref);
    if (field) formData.append('field', field);

    return axios.post(API_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

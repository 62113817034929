export enum TimeDimensionGranularity {
    withoutGrouping = 'withoutGrouping',
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
    quarter = 'quarter',
}

export enum BinaryOperator {
    equals = 'equals',
    notEquals = 'notEquals',
    contains = 'contains',
    notContains = 'notContains',
    startsWith = 'startsWith',
    notStartsWith = 'notStartsWith',
    endsWith = 'endsWith',
    notEndsWith = 'notEndsWith',
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    inDateRange = 'inDateRange',
    notInDateRange = 'notInDateRange',
    beforeDate = 'beforeDate',
    beforeOrOnDate = 'beforeOrOnDate',
    afterDate = 'afterDate',
    afterOrOnDate = 'afterOrOnDate',
}

export enum UnaryOperator {
    set = 'set',
    notSet = 'notSet',
}

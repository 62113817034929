<template>
    <main class="tw-flex tw-flex-row tw-h-full tw-flex-grow tw-flex-shrink-0">
        <div class="tw-w-full tw-z-10 tw-p-4 sm:tw-max-w-lg xl:tw-max-w-3xl tw-rounded-tr-[2.75rem] tw-rounded-br-[2.75rem] tw-bg-white tw-shrink-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-8 md:tw-gap-12">
            <img
                class="tw-max-w-[8rem]"
                src="/logo.svg"
                alt="" />
            <router-view />
        </div>
        <div
            id="login-background"
            class="sm:tw-block tw-hidden tw-grow tw-z-0"></div>
    </main>
</template>

<script setup lang="ts"></script>

<style scoped>
    #login-background {
        background-image: url('@/assets/images/auth/login.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        /* transform: translateX(-50%); */
        margin-left: -2.5rem;
    }
</style>

import { useAuthStore } from '@/store/auth';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';

export const API_URL = '/weather-iotechpis';

export async function getForecast(query = {}): AxiosPromise<any> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/forecast?${searchParams}`);
}

export default {
    getForecast,
};

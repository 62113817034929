import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import { useAuthStore } from '@/store/auth';
import { APIRequestParams } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import qs from 'qs';
import { deleteStatusLabel } from '@/views/AssetsManagement/composables/statusLabels';

const API_URL = '/status-labels';
export const API_REF = 'api::status-label.status-label';

export async function getStatusLabels(query: APIRequestParams<'StatusLabel'> = {}): AxiosPromise<APIResponseMany<'StatusLabel'>> {
    if (!_.has(query, 'filters.companies.id.$in')) _.set(query, 'filters.companies.id.$in', useAuthStore().getUserCompaniesIds);
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}?${searchParams}`);
}

export async function getStatusLabel(id: number, query = {}): AxiosPromise<APIResponseSingle<'StatusLabel'>> {
    const searchParams = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${searchParams}`);
}

export async function createStatusLabel(data: ContentType<'StatusLabel'>): AxiosPromise<APIResponseSingle<'StatusLabel'>> {
    return axios.post(API_URL, { data });
}

export async function updateStatusLabel(id: number, data: ContentType<'StatusLabel'>, query = {}): AxiosPromise<APIResponseSingle<'StatusLabel'>> {
    const searchParams = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${searchParams}`, { data });
}

export async function deleteStatusLabels(ids: number[]): AxiosPromise<APIResponseMany<'StatusLabel'>> {
    const query = qs.stringify({
        filters: {
            id: {
                $in: ids,
            }
        }
    })
    return axios.delete(`${API_URL}/many?${query}`);
}

export async function deleteStatusLabel(id: number): AxiosPromise<APIResponseSingle<'StatusLabel'>> {
    return axios.delete(`${API_URL}/${id}`);
}

export default {
    getStatusLabels,
    getStatusLabel,
    createStatusLabel,
    updateStatusLabel,
    deleteStatusLabels,
    deleteStatusLabel
};

<template>
    <v-breadcrumbs
        class="tw-px-0 tw-pt-0"
        :items="props.items">
        <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</template>

<script setup lang="ts">
    import { useRoute } from 'vue-router';
    import { VBreadcrumbs } from 'vuetify/lib/components/index.mjs';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        items: VBreadcrumbs['$props']['items'];
    }>();

</script>

<style scoped></style>

<template>
    <Sidebar />
    <Navbar />

    <v-main
        class="tw-bg-gray-200 tw-bg-opacity-60"
        :class="{ 'mobile-layout': isMobile }">
        <ioAlert />
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';
    import { LegendComponent, GridComponent, TooltipComponent, DataZoomComponent, ToolboxComponent, MarkLineComponent, TitleComponent, VisualMapComponent } from 'echarts/components';
    import { BarChart, LineChart, PieChart, ScatterChart, RadarChart, HeatmapChart } from 'echarts/charts';
    import { use } from 'echarts/core';
    import Breadcrumbs from './Layout/Breadcrumbs.vue';
    import { computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import _ from 'lodash';
    import { useDisplay } from 'vuetify/lib/framework.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useTheme } from 'vuetify/lib/framework.mjs';
    import { onMounted } from 'vue';
    import { useColor } from '@/composables/useColor';
    const { lgAndUp } = useDisplay();
    const $route = useRoute();

    const useAuth = useAuthStore();
    const primaryColor = useAuth.getPrimaryColor;

    const colors = {
        primary: primaryColor,
    };

    const $theme = useTheme();

    onMounted(() => {
        $theme.themes.value.light.colors = _.merge($theme.themes.value.light.colors, useColor(colors));
    });

    const showBreadcrumbs = computed(() => {
        const route = $route.matched.find((route) => _.has(route.meta, 'breadcrumbs'));
        if (route && route.meta?.breadcrumbs !== undefined) return route.meta.breadcrumbs;

        return lgAndUp.value;
    });

    const isMobile = computed(() => {
        return !lgAndUp.value;
    });

    //watch is mobile
    watch(isMobile, (value) => {
        console.log(value);
    });

    use([
        SVGRenderer,
        CanvasRenderer,
        LegendComponent,
        GridComponent,
        TooltipComponent,
        DataZoomComponent,
        ToolboxComponent,
        BarChart,
        LineChart,
        PieChart,
        ScatterChart,
        RadarChart,
        MarkLineComponent,
        TitleComponent,
        HeatmapChart,
        VisualMapComponent,
    ]);
</script>
<style>
    .mobile-layout {
        --v-layout-left: 80px !important;
    }
</style>

import axios from 'axios';
import { useAuthStore } from '@/store/auth';

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL + '/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        // append company_id to url as query param
        const company_id = useAuthStore().getActiveCompany?.id;
        if (company_id) {
            // CHECK IF URL HAS QUERY PARAMS
            if (config?.url?.includes('?')) {
                config.url += `&companyId=${company_id}`;
            } else config.url += `?companyId=${company_id}`;
        }
        const token = useAuthStore().getToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default instance;

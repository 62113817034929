// This function creates the lines that connect the different energy sources and the home
export const createLines = (path, connection, nonFossil, grid, solar, qivi, home, battery, denimFlash) => {
    //NonFossil-Grid
    if (connection.name === 'NonFossil-Grid') {
        // Set the color and width of the path
        path.style.stroke = '#279a37';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = nonFossil.offsetLeft + nonFossil.offsetWidth / 2;
        const startY = nonFossil.offsetTop + nonFossil.offsetHeight;
        const endX = grid.offsetLeft + grid.offsetWidth / 2;
        const endY = grid.offsetTop;

        // Calculate the control point for the quadratic bezier curve
        const controlX = (startX + endX) / 2;
        const controlY = startY + (endY - startY) / 2;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`;

        path.setAttribute('d', pathData);

        return path;
    }
    //Qivi-Home
    if (connection.name === 'Qivi-Home') {
        // Set the color and width of the path
        path.style.stroke = '#FC8181';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = qivi.offsetLeft + qivi.offsetWidth / 2;
        const startY = qivi.offsetTop + qivi.offsetHeight;
        const endX = home.offsetLeft + home.offsetWidth / 2;
        const endY = home.offsetTop;

        // Calculate the control point for the quadratic bezier curve
        const controlX = (startX + endX) / 2;
        const controlY = startY + (endY - startY) / 2;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`;

        path.setAttribute('d', pathData);

        return path;
    }
    //Home-DenimFlash
    if (connection.name === 'Home-DenimFlash') {
        // Set the color and width of the path
        path.style.stroke = '#93C5FD';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = home.offsetLeft + home.offsetWidth / 2;
        const startY = home.offsetTop + home.offsetHeight;
        const endX = denimFlash.offsetLeft + denimFlash.offsetWidth / 2;
        const endY = denimFlash.offsetTop;

        // Calculate the control point for the quadratic bezier curve
        const controlX = (startX + endX) / 2;
        const controlY = startY + (endY - startY) / 2;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`;

        path.setAttribute('d', pathData);

        return path;

    }
    //Grid-Home
    if (connection.name === 'Grid-Home') {
        // Set the color and width of the path
        path.style.stroke = '#3B82F6';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = grid.offsetLeft + grid.offsetWidth;
        const startY = grid.offsetTop + grid.offsetHeight / 2;
        const endX = home.offsetLeft;
        const endY = home.offsetTop + home.offsetHeight / 2;

        // Calculate the control point for the quadratic bezier curve
        const controlX = startX + (endX - startX) / 2;
        const controlY = startY + (endY - startY) / 2;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`;

        path.setAttribute('d', pathData);

        return path;
    }
    //Solar-Battery
    if (connection.name === 'Solar-Battery') {
        // Set the color and width of the path
        path.style.stroke = '#D53F8C';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = solar.offsetLeft + solar.offsetWidth / 2;
        const startY = solar.offsetTop + solar.offsetHeight;
        const endX = battery.offsetLeft + battery.offsetWidth / 2;
        const endY = battery.offsetTop;

        // Calculate the control point for the quadratic bezier curve
        const controlX = (startX + endX) / 2;
        const controlY = startY + (endY - startY) / 2;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`;

        path.setAttribute('d', pathData);

        return path;
    }
    if (connection.name === 'Solar-Grid') {
        // Set the color and width of the path
        path.style.stroke = '#7c3aed';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = solar.offsetLeft - 10 + solar.offsetWidth / 2;
        const startY = solar.offsetTop + solar.offsetHeight;
        const endX = grid.offsetLeft + 42 + grid.offsetWidth / 2;
        const endY = grid.offsetTop + 27;

        // Calculate the control point for the quadratic bezier curve
        const controlX1 = startX + (endX - startX) / 50;
        const controlY1 = startY + 100;
        const controlX2 = endX - (endX - startX) * 1.2;
        const controlY2 = endY + 40;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} C ${controlX1} ${controlY1} ${controlX2} ${controlY2} ${endX} ${endY}`;

        path.style.fill = 'none';

        path.setAttribute('d', pathData);

        return path;
    }
    //Grid-Battery
    if (connection.name === 'Grid-Battery') {
        // Set the color and width of the path
        path.style.stroke = '#7c3aed';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = battery.offsetLeft - 58 + battery.offsetWidth;
        const startY = battery.offsetTop - 93 + battery.offsetHeight;
        const endX = grid.offsetLeft + 44 + grid.offsetWidth / 2;
        const endY = grid.offsetTop + 64;

        // Calculate the control point for the quadratic bezier curve
        const controlX1 = startX + (endX - startX) / 50;
        const controlY1 = startY - 120;
        const controlX2 = endX - (endX - startX) * 1.2;
        const controlY2 = endY - 30;

        // Construct the path data for the quadratic bezier curve
        const pathData = `M ${startX} ${startY} C ${controlX1} ${controlY1} ${controlX2} ${controlY2} ${endX} ${endY}`;

        path.style.fill = 'none';

        path.setAttribute('d', pathData);

        return path;
    }
    //Solar-Home
    if (connection.name == 'Solar-Home') {
        //  Set the color and width of the path
        path.style.stroke = '#f97316';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = solar.offsetLeft + 11 + solar.offsetWidth / 2;
        const startY = solar.offsetTop + solar.offsetHeight;
        const endX = home.offsetLeft - 43 + home.offsetWidth / 2;
        const endY = home.offsetTop + 26;

        // Calculate the control points for the cubic bezier curve
        const controlX1 = startX + (endX - startX) / 50;
        const controlY1 = startY + 100;
        const controlX2 = endX - (endX - startX) * 1.2;
        const controlY2 = endY + 40;

        // Construct the path data for the cubic bezier curve
        const pathData = `M ${startX} ${startY} C ${controlX1} ${controlY1} ${controlX2} ${controlY2} ${endX} ${endY}`;

        path.style.fill = 'none';

        path.setAttribute('d', pathData);

        return path;
    }
    //Battery-Home
    if (connection.name == 'Battery-Home') {
        // Set the color and width of the path
        path.style.stroke = '#6EE7B7';
        path.style.strokeWidth = '2';

        // Calculate the starting and ending points
        const startX = battery.offsetLeft - 38 + battery.offsetWidth;
        const startY = battery.offsetTop - 94 + battery.offsetHeight;
        const endX = home.offsetLeft - 42 + home.offsetWidth / 2;
        const endY = home.offsetTop + 72;

        // Calculate the control points for the cubic bezier curve
        const controlX1 = startX + (endX - startX) / 50;
        const controlY1 = startY - 110;
        const controlX2 = endX - (endX - startX) * 1.2;
        const controlY2 = endY - 50;

        // Construct the path data for the cubic bezier curve
        const pathData = `M ${startX} ${startY} C ${controlX1} ${controlY1} ${controlX2} ${controlY2} ${endX} ${endY}`;

        // Set the fill of the path to none
        path.style.fill = 'none';

        path.setAttribute('d', pathData);

        return path;
    }
};

//This function creates the circles that move along the paths/lines/connections
export const createCircles = (circle, path, circleCoords, connection) => {
    if (connection.name == 'Solar-Battery' || connection.name == 'Grid-Battery') {
        circle.style.display = 'none';
        return circle;
    } else {
        // Calculate the length of the path
        const pathLength = path.getTotalLength();

        let speed;
        if (connection.name == 'NonFossil-Grid' || connection.name == 'Qivi-Home' || connection.name == 'Home-DenimFlash') speed = 1800;
        else if (connection.name == 'Solar-Grid' || connection.name == 'Battery-Home' || connection.name == 'Solar-Home' || connection.name == 'Battery-Home') speed = 7000;
        else speed = 8000;
        // Calculate the progress along the path (between 0 and 1)
        const progress = performance.now() / speed; // Adjust the speed of the ball by changing the variable speed
        const t = progress % 1; // Ensure t remains between 0 and 1

        // Calculate the coordinates of the point at the given progress
        const point = path.getPointAtLength(t * pathLength);

        // Set the coordinates of the circle to the calculated point
        circleCoords.x = point.x;
        circleCoords.y = point.y;

        // Set the color of the circle based on the connection
        let color;
        if (connection.name == 'NonFossil-Grid') color = '#279a37';
        else if (connection.name == 'Qivi-Home') color = '#FC8181';
        else if (connection.name == 'Home-DenimFlash') color = '#93C5FD';
        else if (connection.name == 'Grid-Home') color = '#3B82F6';
        else if (connection.name == 'Solar-Battery') color = '#D53F8C';
        else if (connection.name == 'Solar-Grid') color = '#7c3aed';
        else if (connection.name == 'Grid-Battery') color = '#7c3aed';
        else if (connection.name == 'Solar-Home') color = '#f97316';
        else if (connection.name == 'Battery-Home') color = '#6EE7B7';

        // Set the color and display of the circle
        circle.style.fill = color;
        circle.style.display = 'block';

        // Hide the circle when it reaches the start or end of the path
        if ((t > 0.9 || t < 0.1) && (connection.name == 'NonFossil-Grid' || connection.name == 'Qivi-Home' || connection.name == 'Home-DenimFlash')) {
            circle.style.display = 'none';
        }
        else if ((t > 0.97 || t < 0.03) && (connection.name == 'Grid-Home' || connection.name == 'Solar-Grid' || connection.name == 'Solar-Home' || connection.name == 'Battery-Home')) {
            circle.style.display = 'none';
        }

        circle.setAttribute('cx', circleCoords.x.toString());
        circle.setAttribute('cy', circleCoords.y.toString());

        return circle;
    }
};
<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <div class="tw-flex tw-gap-x-2">
                    <v-btn
                        elevation="0"
                        density="compact"
                        height="33"
                        width="33"
                        min-width="33"
                        rounded="lg"
                        color="primary"
                        @click="$router.go(-1)">
                        <v-icon class="tw-text-2xl"> mdi-chevron-left </v-icon>
                    </v-btn>
                    <p class="tw-text-2xl tw-font-bold">{{ isAdding ? $t('configs.alarmist.create') : $t('configs.alarmist.edit') }}</p>
                </div>
                <Breadcrumbs
                    class="tw-mt-1 tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-form
            ref="form"
            class="tw-mx-auto tw-py-4">
            <v-row>
                <v-col cols="4">
                    <v-select
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.selectLocation')"
                        :items="locations"
                        item-title="name"
                        item-value="id"
                        required
                        v-model="selectedLocation">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.selectAsset')"
                        :disabled="selectedLocation === null"
                        :items="assets"
                        item-title="name"
                        item-value="id"
                        required
                        v-model="selectedAsset">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.selectSensor')"
                        :disabled="selectedAsset === null"
                        required
                        :items="sensors"
                        v-model="selectedSensor">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.criticality')"
                        :disabled="selectedSensor === null"
                        required
                        item-value="value"
                        :items="['Baixa', 'Média', 'Alta']"
                        v-model="criticality">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.above')"
                        :disabled="selectedSensor == null"
                        :required="above == null && below == null"
                        v-model="above"
                        type="number"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('configs.alarmist.fields.below')"
                        :disabled="selectedSensor == null"
                        :required="above == null && below == null"
                        v-model="below"
                        type="number"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        type="number"
                        min="0"
                        :disabled="above == null && below == null"
                        required
                        v-model="hours"
                        :label="$t('configs.alarmist.fields.hours')"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        type="number"
                        min="0"
                        :disabled="above == null && below == null"
                        required
                        v-model="minutes"
                        :label="$t('configs.alarmist.fields.minutes')"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        type="number"
                        min="0"
                        :disabled="above == null && below == null"
                        required
                        v-model="seconds"
                        :label="$t('configs.alarmist.fields.seconds')"></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-row class="">
            <v-col class="tw-flex tw-justify-end">
                <v-btn
                    :loading="isLoading"
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary"
                    @click="onSubmit">
                    <p class="tw-text-white">{{ $t('shared.save') }}</p></v-btn
                >
            </v-col>
        </v-row>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { getLocations } from '@/views/Settings/composables/location';
    import { getAsset, getAssets } from '@/views/AssetsManagement/composables/assets';
    import alarmist, { getAlarmist, createAlarmist, updateAlarmist } from '@/views/Alarmist/composables/alarmist';
    import ioImg from '@/components/ioImg.vue';
    import { useRoute, useRouter } from 'vue-router';
    import moment from 'moment';
    import { ContentType } from '@/contentTypes';
    import { onMounted, watch } from 'vue';
    import { getErrorKey } from '@/composables/error';
    import { useAuthStore } from '@/store/auth';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { isArray } from 'lodash';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('configs.title'),
            },
            {
                title: $t('configs.alarmist.title'),
                to: { name: 'AlarmistSettings' },
            },
            {
                title: isAdding.value ? $t('configs.alarmist.create') : $t('configs.alarmist.edit'),
            },
        ];
    });

    const $route = useRoute();
    const $router = useRouter();

    const useAuth = useAuthStore();
    const user = useAuth.user;
    const companyId = useAuth.activeCompany?.id;

    const isLoading = ref(false);
    const form = ref();
    const $alert = useAlert();

    const locations = ref<ContentType<'Location'>[]>([]);
    const assets = ref<ContentType<'Asset'>[]>([]);
    const asset = ref<ContentType<'Asset'> | null>(null);
    const settings = ref<ContentType<'Setting'> | null>(null);
    const sensors = ref<ContentType<'Asset'>[]>([]);

    const selectedLocation = ref<string | null>(null);
    const selectedAsset = ref<string | null>(null);
    const selectedSensor = ref<string | null>(null);
    const criticality = ref<string | null>(null);
    const above = ref<number | null>(null);
    const below = ref<number | null>(null);
    const hours = ref<number | null>(null);
    const minutes = ref<number | null>(null);
    const seconds = ref<number | null>(null);

    const isAdding = computed(() => {
        return !$route.params?.id;
    });

    const selectedLocationOnInit = ref(isAdding.value ? false : true);
    const selectedAssetOnInit = ref(isAdding.value ? false : true);

    //watch selectedLocation
    watch(selectedLocation, (newValue) => {
        if (selectedLocationOnInit.value) {
            selectedLocationOnInit.value = false;
            return;
        }
        if (newValue) {
            if (!selectedLocationOnInit.value) fetchAssets(newValue);
        }
        selectedAsset.value = null;
    });

    //watch selectedAsset
    watch(selectedAsset, (newValue) => {
        if (selectedAssetOnInit.value) {
            selectedAssetOnInit.value = false;
            return;
        }
        if (newValue) {
            if (!selectedAssetOnInit.value) fetchAsset(newValue);
        }
        selectedSensor.value = null;
    });

    //watch above
    watch(above, (newValue) => {
        if (newValue) {
            below.value = null;
        }
    });

    //watch below
    watch(below, (newValue) => {
        if (newValue) {
            above.value = null;
        }
    });

    //fetch locations (on mounted)
    async function fetchLocations() {
        isLoading.value = true;
        try {
            await getLocations({
                filters: {
                    company: companyId,
                },
                populate: ['company'],
            }).then((response) => {
                locations.value = response.data.data;
                if (!isAdding.value) fetchAssets(0);
            });
        } catch (error) {
            console.error('Error fetching location data:', error);
        } finally {
            if (isAdding.value) isLoading.value = false;
        }
    }

    //fetch assets (for assets select)
    async function fetchAssets(locationId: number) {
        isLoading.value = true;
        let hasLocationQuery = locationId !== 0;
        try {
            await getAssets({
                filters: {
                    $and: [
                        {
                            name: {
                                $ne: 'Portátil',
                            },
                        },
                        {
                            name: {
                                $ne: 'TV LG',
                            },
                        },
                        {
                            name: {
                                $ne: 'Server Dell 730',
                            },
                        },
                        {
                            name: {
                                $ne: 'Frigorifico',
                            },
                        },
                        hasLocationQuery ? { location: locationId } : {},
                    ],
                },
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                populate: ['supplier', 'image', 'location'],
            }).then((response) => {
                assets.value = response.data.data;
                if (!isAdding.value && selectedLocationOnInit.value && selectedAssetOnInit.value) {
                    fetchAlarmist($route.params?.id as string);
                } else {
                    isLoading.value = false;
                }
            });
        } catch (error) {
            console.error('Error fetching asset data:', error);
        } finally {
            if (isAdding.value) isLoading.value = false;
        }
    }

    //fetch asset (for sensors select)
    async function fetchAsset(id: number) {
        isLoading.value = true;
        try {
            const { data } = await getAsset(id, {
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                populate: ['supplier', 'image', 'location', 'company.settings'],
            });
            asset.value = data.data;
            settings.value = data.data.company.settings;
            sensors.value = asset?.value?.history?.map((item: any) => {
                return item[0].entity_id;
            });
            console.log(sensors.value);
        } catch (error) {
            console.error('Error fetching asset data:', error);
        } finally {
            isLoading.value = false;
        }
    }

    // fetch alarmist (for editing)
    async function fetchAlarmist(id: string) {
        isLoading.value = true;
        try {
            const { data } = await getAlarmist({
                history: false,
            });
            const alarmist = data.find((item: any) => item.id == id);

            const entityId = isArray(alarmist.trigger[0].entity_id) ? alarmist.trigger[0].entity_id[0] : alarmist.trigger[0].entity_id;

            //filter assets, so we can have the asset and location selected on the form, based on the alarmist data, specifically the entity_id
            const filteredAssets = (item) => {
                const entityIds = item.entityIdsHA || item?.history?.map((entity) => entity[0].entity_id);
                return entityIds && entityIds.includes(entityId);
            };
            if (selectedLocationOnInit.value) assets.value = assets.value.filter(filteredAssets);

            selectedLocation.value = assets.value.filter(filteredAssets).map((item) => ({ id: item?.location?.id, name: item?.location?.name }));
            selectedAsset.value = assets.value.map((item) => ({ id: item.id, name: item.name }));
            selectedSensor.value = alarmist.trigger[0].entity_id;
            criticality.value = alarmist.action[0].event_data.criticality == 'high' ? 'Alta' : alarmist.action[0].event_data.criticality == 'medium' ? 'Média' : 'Baixa';
            above.value = alarmist.trigger[0]?.above;
            below.value = alarmist.trigger[0]?.below;
            hours.value = alarmist.trigger[0].for.hours;
            minutes.value = alarmist.trigger[0].for.minutes;
            seconds.value = alarmist.trigger[0].for.seconds;
        } catch (error) {
            console.error('Error fetching alarmist data:', error);
        } finally {
            if (!isAdding.value && selectedLocationOnInit.value && selectedAssetOnInit.value) {
                fetchAsset(selectedAsset?.value[0]?.id as number);
            }
            isLoading.value = false;
        }
    }

    async function onSubmit() {
        if (!form.value) return;

        $alert.hideAlert();
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;
        try {
            let data = {
                entityId: selectedSensor.value,
                during: {
                    hours: hours.value,
                    minutes: minutes.value,
                    seconds: seconds.value,
                },
                criticality: criticality.value == 'Alta' ? 'high' : criticality.value == 'Média' ? 'medium' : 'low',
                above: above.value ? above.value : null,
                below: below.value ? below.value : null,
                location: selectedLocation.value,
            };
            if (isAdding.value) {
                isLoading.value = true;
                createAlarmist(data);
                $alert.showAlert({
                    title: $t('configs.success.create'),
                    type: 'success',
                    timeout: 5000,
                });
                form.value.reset();
                isLoading.value = false;
            } else {
                updateAlarmist($route.params?.id as string, data);
                $alert.showAlert({
                    title: $t('configs.success.edit'),
                    type: 'success',
                    timeout: 5000,
                });
                isLoading.value = false;
            }
        } catch (error) {
            $alert.showAlert({
                text: $t(getErrorKey(error, 'alarmist.configs.errors')),
                title: $t('configs.errors.creating-config'),
                type: 'error',
                timeout: 0,
            });
        } finally {
            isLoading.value = false;
        }
    }

    //fetch locations on mounted
    onMounted(() => {
        fetchLocations();
    });
</script>

<style scoped></style>

import _ from 'lodash';

const useBarChart = (xAxis: any[], series_date: any[], title: string, series_name: string, series_color: string, _options = {}) => {
    const options = {
        title: {
            left: 'center',
            text: title,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                data: xAxis,
                axisTick: {
                    alignWithLabel: true,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
            },
        ],
        series: [
            {
                name: series_name,
                type: 'bar',
                barWidth: '60%',
                data: series_date,
                itemStyle: {
                    color: series_color,
                }
            },
        ],
    };

    return {
        options,
    };
};

export default useBarChart;

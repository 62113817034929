<template>
    <div class="content-container">
        <v-row class="tw-justify-between tw-pt-4">
            <v-col>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mb-2 tw-h-10 xs:tw-mb-0"
                    rounded="lg"
                    color="primary"
                    :loading="props.loading"
                    @click="loadData"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-reload</v-icon>
                    <p class="tw-text-white">
                        {{ $t('shared.load') }}
                    </p>
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                md="auto">
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-mr-2 tw-h-10"
                    rounded="lg"
                    color="primary"
                    @click="clearQuery"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-close</v-icon>
                    <p class="tw-text-white">
                        {{ $t('shared.clear') }}
                    </p>
                </v-btn>
                <v-btn
                    elevation="0"
                    density="compact"
                    class="tw-h-10"
                    rounded="lg"
                    color="primary"
                    :loading="loadingExport"
                    @click="exportCSV"
                    ><v-icon class="tw-pr-2 tw-text-xl tw-text-white">mdi-download</v-icon>
                    <p class="tw-text-white">
                        {{ $t('shared.export') }}
                    </p>
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            id="query-builder"
            class="tw-mb-2 tw-text-sm">
            <!-- Measures -->
            <v-col
                v-if="$cube.measures.value.length"
                cols="12"
                md="6"
                lg="4">
                <v-autocomplete
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.cube.measures')"
                    multiple
                    :menu-props="menuProps"
                    v-model="measures"
                    :items="$cube.measures.value"
                    item-title="title"
                    item-value="name">
                </v-autocomplete>
            </v-col>
            <!-- Dimensions -->
            <v-col
                v-if="$cube.dimensions.value.length"
                cols="12"
                md="6"
                lg="4">
                <v-autocomplete
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.cube.dimensions')"
                    :multiple="chart == 'table'"
                    :menu-props="menuProps"
                    v-model="dimensions"
                    :items="$cube.dimensions.value"
                    item-title="title"
                    item-value="name">
                </v-autocomplete>
            </v-col>
            <!-- Segments -->
            <v-col
                v-if="$cube.segments.value.length"
                cols="12"
                md="6"
                lg="4">
                <v-autocomplete
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.cube.segments')"
                    multiple
                    :menu-props="menuProps"
                    v-model="segments"
                    :items="$cube.segments.value"
                    item-title="title"
                    item-value="name">
                </v-autocomplete>
            </v-col>
            <!-- Time Dimensions -->
            <v-col
                v-if="$cube.timeDimensions.value.length"
                cols="12">
                <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                    <v-autocomplete
                        variant="solo"
                        flat
                        bg-color="white"
                        rounded="lg"
                        center-affix
                        clearable
                        density="compact"
                        :label="$t('intelligence.cube.time')"
                        :menu-props="menuProps"
                        v-model="timeDimension"
                        class="tw-max-w-72"
                        :items="$cube.timeDimensions.value"
                        item-title="title"
                        item-value="name">
                    </v-autocomplete>
                    <template v-if="timeDimension">
                        <v-autocomplete
                            variant="solo"
                            flat
                            bg-color="white"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            :label="$t('intelligence.cube.for')"
                            :menu-props="menuProps"
                            v-model="dateRanges"
                            class="tw-max-w-72"
                            :items="$cube.dateRanges.value"
                            item-value="dateRange"
                            item-title="title">
                        </v-autocomplete>
                        <v-text-field
                            v-if="dateRanges == 'custom'"
                            readonly
                            :value="customRangeText"
                            density="compact"
                            @click="showDatePicker = !showDatePicker"
                            class="tw-min-w-52 tw-max-w-72"
                            dense
                            placeholder="Custom range">
                        </v-text-field>
                        <!-- <VueDatePicker
                        v-if="dateRanges == 'custom'"
                        range
                        v-model="customRange" /> -->
                        <v-autocomplete
                            variant="solo"
                            flat
                            bg-color="white"
                            rounded="lg"
                            center-affix
                            clearable
                            density="compact"
                            :label="$t('intelligence.cube.by')"
                            :menu-props="menuProps"
                            class="tw-max-w-72"
                            v-model="timeGranularity"
                            :items="$cube.timeGranularity.value"
                            item-value="value"
                            item-title="title">
                        </v-autocomplete>
                        <v-dialog
                            v-model="showDatePicker"
                            @update:model-value="_customRange = []">
                            <div class="tw-flex tw-items-center tw-justify-center">
                                <v-date-picker
                                    v-model="_customRange"
                                    multiple="range"
                                    color="primary">
                                    <template #actions>
                                        <v-btn
                                            variant="text"
                                            @click="onCancelDate"
                                            >{{ $t('shared.cancel') }}</v-btn
                                        >
                                        <v-btn
                                            variant="text"
                                            @click="onSetDate"
                                            >{{ $t('shared.ok') }}</v-btn
                                        >
                                    </template>
                                </v-date-picker>
                            </div>
                        </v-dialog>
                    </template>
                </div>
            </v-col>
            <!-- Chart Type -->
            <v-col
                cols="12"
                sm="6"
                lg="4">
                <v-autocomplete
                    variant="solo"
                    flat
                    bg-color="white"
                    rounded="lg"
                    center-affix
                    clearable
                    density="compact"
                    :label="$t('intelligence.cube.chartType')"
                    v-model="chart"
                    class="tw-max-w-72"
                    :items="$chart.chartTypes.value"
                    item-value="value"
                    item-title="text"></v-autocomplete>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import { ref, computed } from 'vue';
    import { useCube } from './composables/useCube';
    import { DateRange, Query, ResultSet } from '@cubejs-client/core';
    import { watchEffect } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { flatten, unflatten } from 'flat';
    import _ from 'lodash';
    import { useChart } from './composables/useChart';
    import { ChartType } from './composables/charts';
    import { useResultSet } from './composables/useResultSet';
    import qs from 'qs';
    import moment from 'moment-timezone';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';

    const { t } = useI18n();

    const $alert = useAlert();
    const $cube = useCube();
    const $router = useRouter();
    const $route = useRoute();
    const $chart = useChart();
    const $resultSet = useResultSet();
    const loadingExport = ref(false);

    const props = defineProps<{
        loading: boolean;
        resultSet?: ResultSet;
    }>();

    const $emit = defineEmits(['load', 'clear']);

    const measures = ref<string[]>([]);
    const dimensions = ref<string[]>([]);
    const segments = ref<string[]>([]);
    const timeDimension = ref<string>();
    const timeGranularity = ref<string>($cube.timeGranularity.value[0].value);
    const dateRanges = ref<DateRange | null>($cube.dateRanges.value[2].dateRange);
    const customRange = ref<string[] | Date[]>([]);
    const _customRange = ref<Date[]>([]);
    const showDatePicker = ref(false);
    const chart = ref<ChartType>($chart.chartTypes.value[$chart.chartTypes.value.length - 1].value);

    const menuProps = {
        class: 'query-builder-autocomplete',
    };

    const query = computed(() => {
        return {
            chart: chart.value,
            measures: !Array.isArray(measures.value) ? [measures.value] : measures.value,
            dimensions: !Array.isArray(dimensions.value) ? [dimensions.value] : dimensions.value,
            segments: !Array.isArray(segments.value) ? [segments.value] : segments.value,
            timeDimensions: timeDimension.value
                ? [
                      {
                          dimension: timeDimension.value,
                          ...(timeGranularity.value == 'withoutGrouping' ? {} : { granularity: timeGranularity.value }),
                          ...(dateRanges.value && dateRanges.value == 'custom' ? { dateRange: customRange.value } : dateRanges.value ? { dateRange: dateRanges.value } : {}),
                      },
                  ]
                : [],
        } as Query;
    });

    const customRangeText = computed(() => {
        if (Array.isArray(customRange.value) && customRange.value.length) {
            return customRange.value.map((date) => moment(date).format('YYYY-MM-DD')).join(' - ');
        }
        return '';
    });

    async function loadData() {
        $emit('load');
    }

    function clearQuery() {
        measures.value = [];
        dimensions.value = [];
        segments.value = [];
        timeDimension.value = undefined;
        timeGranularity.value = $cube.timeGranularity.value[0].value;
        dateRanges.value = $cube.dateRanges.value[2].dateRange;

        $emit('clear');
    }

    async function exportCSV() {
        loadingExport.value = true;
        const q = _.cloneDeep(query.value);
        // @ts-ignore
        delete q.chart;
        try {
            // open link in new tab
            const file = await axios.get(`/cube-js/export-csv/load?${qs.stringify({ query: q, queryType: 'multi' })}`, {
                responseType: 'blob',
            });
            const ts = moment().format('YYYY-MM-DD_HH-mm-ss');
            // make the browser download the file
            const url = window.URL.createObjectURL(new Blob([file.data]));
            const link = document.createElement('a');
            link.href = url;
            // get the filename from the response headers Content-Disposition
            const filename = file.headers['content-disposition'].split('filename=')[1];
            // remove the quotes
            link.setAttribute('download', filename.replace(/"/g, ''));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            $alert.showAlert({
                title: 'Error',
                text: _.get(error, 'response.error.message', _.get(error, 'message', t('shared.unknown_error'))),
                type: 'error',
            });
        } finally {
            loadingExport.value = false;
        }
    }

    function onCancelDate() {
        _customRange.value = [];
        showDatePicker.value = false;
    }

    function onSetDate() {
        const firstDate = moment(_customRange.value[0]).startOf('day');
        // set time at 00:00:00

        const lastDate = moment(_customRange.value[_customRange.value.length - 1]).endOf('day');
        // set time at 23:59:59

        customRange.value = [firstDate.format('YYYY-MM-DDTHH:mm:ss.SSS'), lastDate.format('YYYY-MM-DDTHH:mm:ss.SSS')];

        showDatePicker.value = false;
    }

    watchEffect(() => {
        //@ts-ignore
        $router.push({
            query: flatten(query.value),
        });
    });

    watchEffect(() => {
        $resultSet.setResultSet(props.resultSet);
    });

    try {
        if ($route.query) {
            const _query: any = unflatten($route.query);
            measures.value = _.get(_query, 'measures', []);
            dimensions.value = _.get(_query, 'dimensions', []);
            segments.value = _.get(_query, 'segments', []);
            timeDimension.value = _.get(_query, 'timeDimensions[0].dimension');
            timeGranularity.value = _.get(_query, 'timeDimensions[0].granularity', $cube.timeGranularity.value[0].value);
            const dR = _.get(_query, 'timeDimensions[0].dateRange', $cube.dateRanges.value[2].dateRange);
            if (Array.isArray(dR)) {
                customRange.value = dR;
                dateRanges.value = 'custom';
            } else {
                dateRanges.value = dR;
            }
            chart.value = _.get(_query, 'chart', $chart.chartTypes.value[$chart.chartTypes.value.length - 1].value);
        }
    } catch (error) {
        console.error(error);
    }
</script>

<style>
    /* #query-builder .v-field,
    .query-builder-autocomplete .v-list-item-title {
        @apply tw-text-sm;
    } */
</style>

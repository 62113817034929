<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <div class="tw-flex tw-gap-x-2">
                    <v-btn
                        elevation="0"
                        density="compact"
                        height="33"
                        width="33"
                        min-width="33"
                        rounded="lg"
                        color="primary"
                        @click="$router.go(-1)">
                        <v-icon class="tw-text-2xl"> mdi-chevron-left </v-icon>
                    </v-btn>
                    <p class="tw-text-2xl tw-font-bold">{{ $t('assets-management.assets.detail') }}</p>
                </div>
                <Breadcrumbs
                    class="tw-mt-1 tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <div
            v-if="errorMessage"
            class="">
            <v-alert
                elevation="0"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>
        <v-row>
            <v-col>
                <div v-if="asset">
                    <v-tabs
                        density="dense"
                        color="white"
                        class="tabs-btn tw-mb-4 tw-py-4 tw-pr-2"
                        selected-class="tw-bg-primary"
                        hide-slider
                        v-model="tab">
                        <v-tab :value="1">{{ $t('assets-management.assets.tabs.info') }}</v-tab>
                        <v-tab
                            v-if="showHistoricalData"
                            :value="2"
                            >{{ $t('assets-management.assets.tabs.history') }}</v-tab
                        >
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item :value="1">
                            <v-table>
                                <tbody>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.name') }}</td>
                                        <td>{{ asset.name }}</td>
                                    </tr>
                                    <!--
                                           <tr>
                                            <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.serial') }}</td>
                                            <td>{{ asset.serial }}</td>
                                        </tr>
                                        -->
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.supplier') }}</td>
                                        <td>{{ getSupplierName }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.lastAudit') }}</td>
                                        <td>{{ getGetLastAuditDate }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.nextAudit') }}</td>
                                        <td>{{ getNextAuditDate }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.byod') }}</td>
                                        <td>{{ getBYOD }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.notes') }}</td>
                                        <td>{{ getNotes }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.puchaseCost') }}</td>
                                        <td>{{ getPurchaseCost }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.purchaseDate') }}</td>
                                        <td>{{ getPurchaseDate }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.geolocation') }}</td>
                                        <td>{{ getGeolacation }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tw-font-bold">{{ $t('assets-management.assets.info.table.image') }}</td>
                                        <td>
                                            <io-img
                                                format="thumbnail"
                                                :media="getMedia"
                                                content-type="Asset"
                                                width="75"
                                                height="75"
                                                contain></io-img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            rowspan="3"
                                            class="tw-font-bold">
                                            {{ $t('assets-management.assets.info.table.location') }}
                                        </th>
                                        <td>{{ getAddress }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getZipCode }} {{ getName }}</td>
                                    </tr>

                                    <tr>
                                        <td>{{ getState }}, {{ getCity }}, {{ getCountry }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-window-item>
                        <v-window-item
                            v-if="showHistoricalData"
                            :value="2">
                            <v-container fluid>
                                <v-expansion-panels class="mb-6">
                                    <v-expansion-panel>
                                        <v-expansion-panel-title
                                            expand-icon="mdi-plus"
                                            collapse-icon="mdi-minus">
                                            Selecionar Datas
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text>
                                            <v-row>
                                                <v-col
                                                    md="12"
                                                    lg="4"
                                                    cols="12">
                                                    <v-label>{{ $t('assets-management.assets.history.since') }}</v-label>
                                                    <v-date-picker
                                                        :hide-header="true"
                                                        v-model="min_date"
                                                        scrollable></v-date-picker>
                                                </v-col>
                                                <v-col
                                                    md="12"
                                                    lg="4"
                                                    cols="12">
                                                    <v-label>{{ $t('assets-management.assets.history.until') }}</v-label>
                                                    <v-date-picker
                                                        :hide-header="true"
                                                        v-model="max_date"
                                                        scrollable></v-date-picker>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        color="primary"
                                                        @click="init"
                                                        >{{ $t('shared.search') }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                                <v-row v-if="done">
                                    <v-col
                                        lg="3"
                                        md="4"
                                        sm="6"
                                        cols="12"
                                        v-for="sensor in asset.history[0]"
                                        :key="sensor">
                                        <v-card variant="flat"
                                            v-if="sensor.length == 1"
                                            class="mx-auto"
                                            >
                                            <v-card-item>
                                                <v-card-title class="tw-text-base lg:tw-text-xl"> {{ sensor[0].attributes.friendly_name }} </v-card-title>
                                            </v-card-item>

                                            <v-card-text>{{ sensor[0].state }} {{ sensor[0].attributes.device_class }}</v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row v-if="done">
                                    <v-col
                                        class="tw-mb-4 tw-h-96"
                                        lg="6"
                                        cols="12"
                                        v-for="sensor in asset.history[1]"
                                        :key="sensor">
                                        <v-card variant="flat"
                                            class="tw-h-96"
                                            >
                                            <Chart
                                                type="line"
                                                :data="getData(sensor)"
                                                :xAxisInfo="getXAxisInfo(sensor)"
                                                :title="sensor[0].attributes.friendly_name"
                                                :series_name="sensor[0].attributes.unit_of_measurement" />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-window-item>
                    </v-window>
                </div>
            </v-col>
        </v-row>
    </div>
    <v-overlay
        :model-value="isLoading"
        class="tw-items-center tw-justify-center"
        persistent>
        <v-progress-circular
            indeterminate
            size="64"
            color="primary"></v-progress-circular>
    </v-overlay>
</template>

<script setup lang="ts">
    import { ref, provide, computed } from 'vue';
    import { getAsset } from '@/views/AssetsManagement/composables/assets';
    import { useRoute } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { getErrorKey } from '@/composables/error';
    import { useI18n } from 'vue-i18n';
    import { ContentType } from '@/contentTypes';
    import IoImg from '@/components/ioImg.vue';

    import { AssetHistoryAttributes, AssetHistory } from '../../../contentTypes';
    import moment from 'moment';
    import Chart from '@/components/charts/Chart.vue';

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('assets-management.title'),
                to: { name: 'AM-Assets' },
            },
            {
                title: $t('assets-management.assets.title'),
                to: { name: 'AM-Assets' },
            },
            {
                title: $t('assets-management.assets.detail'),
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const $route = useRoute();
    const id = ref($route.params.id);
    const $t = useI18n().t;
    const isLoading = ref(false);
    const errorMessage = ref('');
    const tab = ref(null);
    const asset = ref<ContentType<'Asset'>>();
    const min_date = ref();
    const max_date = ref();
    let done = ref(false);

    const showHistoricalData = computed(() => {
        return asset.value?.history != null && asset.value?.history != undefined;
    });

    const getSupplierName = computed(() => {
        return asset.value?.supplier?.name ? asset.value?.supplier?.name : 'N/A';
    });

    const getGetLastAuditDate = computed(() => {
        return asset.value?.lastAuditDate ? asset.value?.lastAuditDate : 'N/A';
    });

    const getNextAuditDate = computed(() => {
        return asset.value?.nextAuditDate ? asset.value?.nextAuditDate : 'N/A';
    });

    const getNotes = computed(() => {
        return asset.value?.notes ? asset.value?.notes : 'N/A';
    });

    const getPurchaseDate = computed(() => {
        return asset.value?.purchaseDate ? asset.value?.purchaseDate : 'N/A';
    });

    const getGeolacation = computed(() => {
        return asset.value?.geolocation ? asset.value?.geolocation : 'N/A';
    });
    const getBYOD = computed(() => {
        return asset.value?.byod ? asset.value?.byod : 'N/A';
    });
    const getPurchaseCost = computed(() => {
        return asset.value?.purchaseCost ? asset.value?.purchaseCost : 'N/A';
    });

    const getMedia = computed(() => {
        return asset.value?.image;
    });

    const getCity = computed(() => {
        return asset.value?.location?.city ? asset.value?.location?.city : 'N/A';
    });

    const getState = computed(() => {
        return asset.value?.location?.state ? asset.value?.location?.state : 'N/A';
    });

    const getName = computed(() => {
        return asset.value?.location?.name ? asset.value?.location?.name : 'N/A';
    });

    const getAddress = computed(() => {
        return asset.value?.location?.address ? asset.value?.location?.address : 'N/A';
    });

    const getZipCode = computed(() => {
        return asset.value?.location?.zip ? asset.value?.location?.zip : 'N/A';
    });

    const getCountry = computed(() => {
        return asset.value?.location?.country ? asset.value?.location?.country : 'N/A';
    });

    async function init() {
        isLoading.value = true;
        errorMessage.value = '';
        if (max_date.value) {
            max_date.value.setHours(23, 59, 59);
        }
        try {
            const { data } = await getAsset(Number(id.value), {
                stateChanges: true,
                minimalResponse: true,
                significantChangesOnly: true,
                ...(min_date.value && { startTime: moment(min_date.value).format('YYYY-MM-DD[T]HH:mm:ss') }),
                ...(max_date.value && { endTime: moment(max_date.value).format('YYYY-MM-DD[T]HH:mm:ss') }),
                populate: ['supplier', 'image', 'location'],
            });
            asset.value = data.data;

            // split hisotry in two arrays where in first array apperas elements with length == 1 and in second array apperas elements with length > 1
            asset.value.history = asset.value.history?.reduce(
                (acc: any, item: any) => {
                    if (item.length == 1) {
                        acc[0].push(item);
                    } else {
                        acc[1].push(item);
                    }
                    return acc;
                },
                [[], []],
            );
            done.value = true;
        } catch (error) {
            const messageKey = getErrorKey(error, `assets-management.assets.errors`);
            errorMessage.value = $t(messageKey);
        } finally {
            isLoading.value = false;
        }
    }

    function getData(data: any) {
        return data.map((item: AssetHistory) => item.state);
    }

    function getXAxisInfo(data: any) {
        return data.map((item: AssetHistory) => moment(item.last_changed).format('YYYY-MM-DD HH:mm:ss'));
    }

    init();
</script>

<style scoped></style>

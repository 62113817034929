<template>
    <v-row class="header-container">
        <v-col
            align-self="center"
            cols="10"
            sm="6">
            <div>
                <p class="tw-text-xl tw-font-bold">{{ $t('industry.control.laundry.title') }}</p>
                <Breadcrumbs
                    class="tw-hidden lg:tw-block"
                    :items="breadcrumbs">
                </Breadcrumbs>
            </div>
        </v-col>
        <v-col
            cols="2"
            sm="6">
            <div class="tw-flex tw-items-center tw-justify-end tw-gap-x-2">
                <p class="tw-hidden sm:tw-block">
                    {{ user.username }}
                </p>
                <v-badge
                    color="primary"
                    content="9"
                    offset-x="3"
                    offset-y="5">
                    <v-avatar size="45">
                        <io-img
                            format="thumbnail"
                            class="no-padding tw-rounded-full"
                            :media="user?.picture"
                            content-type="Media"></io-img>
                    </v-avatar>
                </v-badge>
            </div>
        </v-col>
    </v-row>
    <div class="content-container">
        <v-row class="tw-py-4">
            <v-col
                v-for="hvac in hvacs"
                class="mx-auto">
                <v-card
                    variant="flat"
                    class="mx-auto tw-h-full tw-p-4">
                    <v-row>
                        <v-col cols="12">
                            <v-card-subtitle> {{ hvac.location }} </v-card-subtitle>
                        </v-col>
                        <v-card-text>
                            <div class="tw-flex tw-items-center tw-justify-center tw-gap-x-16">
                                <div>
                                    <div class="tw-mb-1 tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-red-200 tw-bg-opacity-100 tw-p-4 tw-text-red-500">mdi-identifier</v-icon>
                                        <p>{{ hvac.id }}</p>
                                    </div>
                                    <div class="tw-mb-1 tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-green-200 tw-bg-opacity-100 tw-p-4 tw-text-green-500"> mdi-thermometer </v-icon>
                                        <p>{{ hvac.temperature }} °C</p>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-gap-x-2">
                                        <v-icon class="tw-rounded-md tw-bg-blue-200 tw-bg-opacity-100 tw-p-4 tw-text-blue-500">mdi-water-percent</v-icon>
                                        <p>{{ hvac.humidity }} %</p>
                                    </div>
                                </div>
                                <div class="tw-text-center">
                                    <p class="tw-text-4xl tw-font-semibold">{{ hvac.value }} L</p>
                                </div>
                            </div>
                            <div class="tw-mt-8 tw-flex tw-justify-center tw-gap-x-2">
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-power"
                                    flat
                                    :color="hvac.on ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('on', hvac.id)">
                                    <p>on</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-power-off"
                                    flat
                                    :color="hvac.off ? 'primary' : 'gray-200'"
                                    size="large"
                                    @click="toggleState('off', hvac.id)">
                                    <p>off</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-pause"
                                    flat
                                    :color="hvac.pause ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('pause', hvac.id)">
                                    <p>pause</p>
                                </v-btn>
                                <v-btn
                                    stacked
                                    prepend-icon="mdi-restart"
                                    flat
                                    :color="hvac.restart ? 'primary' : 'gray-200'"
                                    class="tw-text-gray-600"
                                    size="large"
                                    @click="toggleState('restart', hvac.id)">
                                    <p>restart</p>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';

    const $t = useI18n().t;

    import Breadcrumbs from '@/views/Breadcrumbs.vue';
    const breadcrumbs = computed(() => {
        return [
            {
                title: $t('industry.title'),
            },
            {
                title: $t('industry.control.title'),
            },
            {
                title: $t('industry.control.laundry.title'),
                to: { name: 'Laundry' },
            },
        ];
    });

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const hvacs = ref([
        {
            id: 1354,
            value: 179,
            humidity: 60,
            status: 'decreasing',
            location: 'Máquina 5',
            temperature: 29,
            on: false,
            off: true,
            pause: false,
            restart: false,
        },
    ]);

    const toggleState = (state: string, hvacId: number) => {
        const hvac = hvacs.value.find((hvac) => hvac.id === hvacId);
        hvac.on = state === 'on';
        hvac.off = state === 'off';
        hvac.pause = state === 'pause';
        hvac.restart = state === 'restart';
    };
</script>
<style></style>

<template>
    <v-dialog
        v-model="isOpen"
        max-width="420">
        <v-card variant="flat"
            class="tw-py-1"
            :title="$t('components.confirmDelete.title')">
            <template v-slot:prepend>
                <v-icon class="tw-rounded-md tw-bg-red-100 tw-bg-opacity-100 tw-p-4 tw-text-red-500">mdi-delete</v-icon>
            </template>
            <v-card-text> {{ $t('components.confirmDelete.text') }} </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    elevation="0"
                    density="compact"
                    height="auto"
                    class="tw-h-10 tw-bg-gray-300"
                    rounded="lg"
                    color="gray-600"
                    @click="onAction('no')"
                    >{{ $t('shared.no') }}</v-btn
                >
                <v-btn
                    elevation="0"
                    density="compact"
                    height="auto"
                    class="tw-h-10 tw-bg-primary"
                    rounded="lg"
                    color="white"
                    @click="onAction('yes')"
                    >{{ $t('shared.yes') }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { is } from '@babel/types';
    import { watch } from 'vue';
    import { ref } from 'vue';

    const props = defineProps<{
        modelValue: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean): void;
        (e: 'action', value: string): void;
    }>();

    const isOpen = ref(false);

    function onAction(value: string) {
        emit('action', value);
        isOpen.value = false;
    }

    watch(
        () => props.modelValue,
        (value) => {
            isOpen.value = value;
        },
        {
            immediate: true,
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            emit('update:modelValue', value);
        },
    );
</script>

<style scoped></style>
